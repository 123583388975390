.error404 {
  width: 60vw;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.error404-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60vw;
  position: relative;
}
.error404-image-number {
  width: 50vw;
}
.error404-image-logo {
  position: absolute;
  right: 10vw;
  top: 7vw;
  width: 10vw;
  height: 10vw;
}
.error404-text {
  font-size: 1vw;
  text-align: center;
}/*# sourceMappingURL=error404.styles.css.map */