.InsightCard {
	width: 280px;
	height: 546px;
	flex-shrink: 0;
}

.InsightCardHoverAction {
	display: flex;
	position: absolute;
	width: inherit;
	height: 339px;
	opacity: 0;
	z-index: 200;
	&:hover {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		background-color: rgba(60, 60, 60, 0.8);
		transition: 0.3s;
		opacity: 1;
		div {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 192px;
			height: 44px;
			color: #fff;
			text-align: center;
			font-family: Pretendard;
			font-size: 20px;
			font-style: normal;
			font-weight: 300;
			line-height: 45px;
			border: 1px solid white;
			border-radius: 10px;
			align-items: center;
		}
	}
}

.InsightImage {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 280px;
	height: 339px;
	flex-shrink: 0;
	opacity: 0.9;
	background: var(--Light-gray, #f0f0f0);
	&:hover {
		cursor: pointer;
	}
}

.InsightCategory {
	color: #000;
	font-family: Pretendard;
	font-size: 20px;
	font-style: normal;
	font-weight: 300;
	line-height: 28px;
	opacity: 0.9;
	margin-top: 14px;
}

.InsightTitle {
	color: #000;
	font-family: Pretendard;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
	opacity: 0.9;
	margin-top: 14px;
}

.ResearchDate {
	color: var(--Midium-gray, #9e9e9e);
	font-family: Pretendard;
	font-size: 20px;
	font-style: normal;
	font-weight: 300;
	line-height: 28px;
}

.ViewCount {
	display: flex;
	align-items: center;
	margin-top: 14px;

	img {
		width: 15px;
		height: 10px;
		flex-shrink: 0;
	}

	label {
		color: var(--Midium-gray, #9e9e9e);
		font-family: Pretendard;
		font-size: 16px;
		font-style: normal;
		font-weight: 300;
		line-height: 28px;
		margin-left: 9px;
	}
}

.insights {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	margin-bottom: 150px;
	& * {
		word-break: keep-all;
	}
	&-head {
		display: flex;
		flex-direction: column;
		width: 1194px;
		margin: auto;

		margin-top: 190px;
		.big {
			margin-bottom: 30px;

			color: #000;

			/* Pre.Semi 60 */
			font-family: Pretendard;
			font-size: 60px;
			font-style: normal;
			font-weight: 600;
			line-height: 28px; /* 46.667% */
		}
		.small {
			color: var(--Midium-gray, #9e9e9e);

			/* web. semi.30 */
			font-family: Pretendard;
			font-size: 30px;
			font-style: normal;
			font-weight: 600;
			line-height: 28px; /* 93.333% */
		}
	}
	&-gnb {
		width: 1194px;
		margin: auto;
		margin-top: 50px;
		margin-bottom: 21px;
		&-body {
			display: flex;
			flex-direction: row;
			width: 825px;
			//justify-content: space-b;
			button {
				padding: 12px 30px;
				border-bottom: 1px solid;
				background: #fff;
				text-align: center;
				color: var(--Midium-gray, #9e9e9e);
				font-family: Pretendard;
				font-size: 22px;
				font-style: normal;
				font-weight: 400;
				line-height: 12px;

				&:hover {
					background: var(--Light-gray, #f0f0f0);
					color: #000;
				}

				&:focus {
					background: var(--Dark-gray, #3c3c3c);
					color: #fff;
				}
			}
			button:hover {
				cursor: pointer;
			}
		}
	}
	.waitPlease {
		width: 1192px;
		margin: auto;
	}
	&-body {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		column-gap: 24px;
		row-gap: 32px;
		overflow: hidden;
		width: 1192px;
		max-height: 1314px;

		margin: auto;

		.insight-info {
			display: flex;
			flex-direction: column;
			width: 280px;
			height: 430px;

			&-box {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 280px;
				height: 280px;
				flex-shrink: 0;
				background: var(--Light-gray, #f0f0f0);
				// .pageContainer {
				// 	width: 180px;
				// 	.page {
				// 		max-width: 100%;
				// 		max-height: 180px;
				// 	}
				// }
			}
			.learnMore {
				@extend .insight-info-box;
				background-color: #3c3c3c;
				position: absolute;
				opacity: 0;
				z-index: 40;
				.text {
					display: flex;
					padding: 8px 46px;
					justify-content: center;
					align-items: center;
					gap: 10px;
					border-radius: 10px;
					color: white;
					border: 1px solid var(--Light-gray, #f0f0f0);
					text-align: center;
					/* web. light.20 */
					font-family: Pretendard;
					font-size: 20px;
					font-style: normal;
					font-weight: 300;
					line-height: 28px; /* 140% */
				}
			}
			.learnMore:hover {
				cursor: pointer;
				opacity: 0.8;
			}
			&-type {
				margin-top: 10px;
				color: var(--point, #1acfaf);

				/* web. light.20 */
				font-family: Pretendard;
				font-size: 20px;
				font-style: normal;
				font-weight: 300;
				line-height: 28px; /* 140% */
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				.insight-views {
					display: flex;
					flex-direction: row;
					align-items: center;
					width: fit-content;
					color: var(--Midium-gray, #9e9e9e);
					font-family: Pretendard;
					font-size: 16px;
					font-style: normal;
					font-weight: 300;
					line-height: 28px; /* 175% */
					img {
						margin-right: 8px;
					}
				}
			}
			&-title {
				margin-top: 10px;
				color: #000;
				font-family: Pretendard;
				font-size: 22px;
				font-style: normal;
				font-weight: 600;
				line-height: 32px; /* 145.455% */
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			&-date {
				margin-top: 10px;
				color: var(--Midium-gray, #9e9e9e);

				/* web. light.20 */
				font-family: Pretendard;
				font-size: 20px;
				font-style: normal;
				font-weight: 300;
				line-height: 28px; /* 140% */
			}
		}
		.insight-info:hover {
			cursor: pointer;
		}
	}
	&-readmore {
		width: 186px;
		height: 44px;
		margin: auto;
		border-radius: 10px;
		border: 1px solid var(--Dark-gray, #3c3c3c);
		background-color: white;
		color: var(--Dark-gray, #3c3c3c);
		text-align: center;
		margin-top: 62px;
		/* web. light.20 */
		font-family: Pretendard;
		font-size: 20px;
		font-style: normal;
		font-weight: 300;
		line-height: 28px; /* 140% */
	}
	&-readmore:hover {
		cursor: pointer;
		background: var(--Light-gray, #f0f0f0);
	}
}

@media (max-width: 1400px) {
	.InsightCard {
		width: 210px;
		height: 458px;
	}

	.InsightCardHoverAction {
		height: 254px;
	}

	.InsightImage {
		width: 210px;
		height: 254px;
	}

	.InsightCategory {
		font-size: 17px;
	}

	.InsightTitle {
		font-size: 18.5px;
		line-height: 22px;
	}

	.ViewCount {
		margin-top: 10px;
	}

	.insights {
		&-head {
			width: 900px;
		}
		&-gnb {
			width: 900px;
		}
		.waitPlease {
			width: 900px;
			margin: auto;
		}
		&-body {
			column-gap: 18px;
			row-gap: 22px;
			width: 900px;
			//width: 1192px;
			max-height: 992px;

			.insight-info {
				width: 211.4px;
				height: 324.66px;

				&-box {
					width: 211.4px;
					height: 211.4px;
				}
				.learnMore {
					.text {
						padding: 4px 26px;
						gap: 10px;
						font-size: 15px;
					}
				}
				&-type {
					margin-top: 7.55px;

					font-size: 15px;
				}
				&-title {
					margin-top: 5px;

					font-size: 16.6px;

					line-height: 1.2; /* 145.455% */
				}
				&-date {
					//margin-top: 7.55px;

					font-size: 15px;

					line-height: 0.8; /* 140% */
				}
			}
		}
		&-readmore {
			width: 120px;
			height: 35px;
			font-size: 15px;
			line-height: 2.4;
		}
	}
}

@media (max-width: 1023px) {
	.InsightCard {
		width: 162px;
		height: 356px;
	}

	.InsightCardHoverAction {
		height: 197.5px;
	}

	.InsightImage {
		width: 162px;
		height: 197.5px;
	}

	.InsightCategory {
		font-size: 13px;
		line-height: 20px;
		margin-top: 10px;
	}

	.InsightTitle {
		font-size: 14px;
		margin-top: 8px;
		line-height: 20px;
	}

	.ViewCount {
		margin-top: 10px;

		img {
			width: 12px;
			height: 8px;
			flex-shrink: 0;
		}

		label {
			font-size: 12px;
			line-height: 15px;
			margin-left: 5px;
		}
	}

	.insights {
		padding-left: 0px;
		&-head {
			width: 700px;

			.big {
				width: 700px;
				font-size: 45px;
			}
			.small {
				width: 700px;
				font-size: 23px;
			}
		}
		&-gnb {
			width: 700px;
			&-body {
				width: 700px;
				//margin-left: -50px;
				button {
					font-size: 16.7px;
					padding: 11px 30px;
				}
			}
		}
		.waitPlease {
			width: 700px;
			margin: auto;
		}
		&-body {
			width: 700px;
			//width: 1192px;
			max-height: 771.64px;

			column-gap: 14.1px;
			row-gap: 22px;
			.insight-info {
				width: 164.4px;
				height: 252.5px;

				&-box {
					width: 164.4px;
					height: 164.4px;
				}
				.learnMore {
					.text {
						padding: 2px 15px;

						font-size: 12px;
					}
				}
				&-type {
					margin-top: 5px;

					font-size: 11px;
					line-height: 1.5; /* 140% */
					.insight-views {
						display: flex;
						flex-direction: row;
						align-items: center;
						width: fit-content;
						color: var(--Midium-gray, #9e9e9e);
						font-family: Pretendard;
						font-size: 11px;
						font-style: normal;
						font-weight: 300;
						line-height: 1.75; /* 175% */
						img {
							margin-right: 8px;
							width: 12px;
						}
						span {
							margin-top: 2px;
						}
					}
				}
				&-title {
					margin-top: 2px;
					font-size: 12px;
					line-height: 1.2; /* 145.455% */
				}
				&-date {
					font-size: 11px;
					line-height: 0.8; /* 140% */
				}
			}
		}
		&-readmore {
			text-align: center;
		}
	}
}

@media (max-width: 767px) {
	.InsightCard {
		width: 81px;
		height: 225px;
	}

	.InsightCardHoverAction {
		height: 98px;
		&:hover {
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			background-color: rgba(60, 60, 60, 0.8);
			transition: 0.3s;
			opacity: 1;
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 60px;
				height: 25px;
				color: #fff;
				text-align: center;
				font-family: Pretendard;
				font-size: 10px;
				font-style: normal;
				font-weight: 300;
				line-height: 25px;
				border: 1px solid white;
				border-radius: 10px;
				align-items: center;
			}
		}
	}

	.InsightImage {
		width: 80px;
		height: 98px;
		.react-pdf__Page__canvas {
			width: 56px !important;
			height: 80px !important;
		}
	}

	.InsightCategory {
		font-size: 7.5px;
		margin-top: 5.5px;
		line-height: 10px;
	}

	.InsightTitle {
		font-size: 8px;
		margin-top: 6px;
		line-height: 11.5px;
	}

	.ViewCount {
		margin-top: 5px;

		img {
			width: 10px;
			height: 7px;
			flex-shrink: 0;
		}

		label {
			font-size: 10px;
			line-height: 15px;
			margin-left: 5px;
		}
	}

	.insights {
		&-head {
			width: 350px;
			padding-left: 0px;

			.big {
				width: 350px;
				font-size: 35px;
			}
			.small {
				width: 350px;
				font-size: 18px;
			}
		}
		&-gnb {
			width: 350px;

			&-body {
				width: 350px;
				button {
					padding: 5px 8.5px;
					font-size: 11px;
					line-height: 15px;
				}
			}
		}
		.waitPlease {
			width: 350px;
			font-size: 18px;
			margin: auto;
		}
		&-body {
			grid-template-columns: repeat(2, 1fr);

			width: 350px;
			max-height: 1000px;
			column-gap: 10px;
			row-gap: 4px;
			.insight-info {
				width: 170px;
				height: 288px;
				margin-bottom: 0px;
				&-box {
					width: 170px;
					height: 188px;
				}
				.learnMore {
					.text {
						padding: 2px 15px;
						gap: 0px;
						font-size: 12px;
					}
				}
				&-type {
					margin-top: 10px;
					font-size: 15px;
					line-height: 1; /* 140% */
				}
				&-title {
					//margin-top: 5px;
					width: 170px;
					height: 20px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin-top: 5px;
					font-size: 20px;
					line-height: 1; /* 145.455% */
				}
				&-date {
					margin-top: 5px;
					font-size: 15px;
					line-height: 1.5; /* 140% */
				}
			}
		}
		&-readmore {
			width: 80px;
			height: 20px;
			border-radius: 6px;
			font-size: 12px;
			line-height: 1.5;
		}
	}
}
