.iDetail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.iDetail * {
  word-break: keep-all;
}
.iDetail-back {
  width: 1192px;
  margin: auto;
  display: flex;
  flex-direction: row;
  margin-top: 132px;
  align-items: center;
}
.iDetail-back-box {
  position: relative;
  display: flex;
  flex-direction: row;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: end;
  align-items: center;
}
.iDetail-back-box div {
  color: #000;
  margin-top: 2px;
  /* web. re.22 */
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 127.273% */
  margin-left: 40px;
}
.iDetail-back-box img {
  position: absolute;
  left: 0px;
}
.iDetail-back-box:hover {
  cursor: pointer;
}
.iDetail-back-box:hover img {
  left: -10px;
  transition: 0.2s ease-in-out;
}
.iDetail-title {
  width: 1192px;
  margin: auto;
  color: #000;
  margin-top: 30px;
  /* Pre.Semi 60 */
  font-family: Pretendard;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 70px; /* 46.667% */
}
.iDetail-typedate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1192px;
  margin: auto;
  margin-top: 30px;
}
.iDetail-typedate-type, .iDetail-typedate-date {
  color: var(--Midium-gray, #9e9e9e);
  /* web. semi.30 */
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 93.333% */
}
.iDetail-pdf {
  width: 1192px;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-top: 100px;
}
.iDetail-pdf .pageContainer {
  margin-bottom: 50px; /* Add margin between pages */
}
.iDetail-back-container {
  width: 1196px;
  height: 200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iDetail-back-container .readmore {
  width: 186px;
  height: 44px;
  margin: auto;
  border-radius: 10px;
  border: 1px solid var(--Dark-gray, #3c3c3c);
  background-color: white;
  color: var(--Dark-gray, #3c3c3c);
  text-align: center;
  /* web. light.20 */
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px; /* 140% */
}
.iDetail-back-container .readmore:hover {
  cursor: pointer;
  background: var(--Light-gray, #f0f0f0);
}

@media (max-width: 1400px) {
  .iDetail {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .iDetail-back {
    width: 900px;
  }
  .iDetail-back div {
    color: #000;
  }
  .iDetail .researcherdetail-back:hover {
    cursor: pointer;
  }
  .iDetail-title {
    width: 900px;
    margin: auto;
    color: #000;
    margin-top: 30px;
    /* Pre.Semi 60 */
    font-family: Pretendard;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px; /* 46.667% */
  }
  .iDetail-typedate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 900px;
    margin: auto;
    margin-top: 25px;
  }
  .iDetail-typedate-type, .iDetail-typedate-date {
    color: var(--Midium-gray, #9e9e9e);
    /* web. semi.30 */
    font-family: Pretendard;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 93.333% */
  }
  .iDetail-pdf {
    width: 900px;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    margin-top: 100px;
  }
  .iDetail-pdf .pageContainer {
    margin-bottom: 50px; /* Add margin between pages */
  }
  .iDetail-back-container {
    width: 900px;
  }
}
@media (max-width: 1023px) {
  .iDetail {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .iDetail-back {
    width: 700px;
  }
  .iDetail .researcherdetail-back:hover {
    cursor: pointer;
  }
  .iDetail-title {
    width: 700px;
    margin: auto;
    color: #000;
    margin-top: 30px;
    /* Pre.Semi 60 */
    font-family: Pretendard;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 46.667% */
  }
  .iDetail-typedate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 700px;
    margin: auto;
    margin-top: 25px;
  }
  .iDetail-typedate-type, .iDetail-typedate-date {
    color: var(--Midium-gray, #9e9e9e);
    /* web. semi.30 */
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 93.333% */
  }
  .iDetail-pdf {
    width: 700px;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    margin-top: 60px;
  }
  .iDetail-pdf .pageContainer {
    margin-bottom: 50px; /* Add margin between pages */
  }
  .iDetail-back-container {
    width: 700px;
  }
}
@media (max-width: 767px) {
  .iDetail {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .iDetail-back {
    width: 350px;
  }
  .iDetail-back-box img {
    width: 20px;
  }
  .iDetail-back-box div {
    font-size: 15px;
    margin-left: 30px;
  }
  .iDetail .researcherdetail-back:hover {
    cursor: pointer;
  }
  .iDetail-title {
    width: 350px;
    margin: auto;
    color: #000;
    margin-top: 30px;
    /* Pre.Semi 60 */
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px; /* 46.667% */
  }
  .iDetail-typedate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 350px;
    margin: auto;
    margin-top: 25px;
  }
  .iDetail-typedate-type, .iDetail-typedate-date {
    color: var(--Midium-gray, #9e9e9e);
    /* web. semi.30 */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 93.333% */
  }
  .iDetail-pdf {
    width: 350px;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    margin-top: 40px;
  }
  .iDetail-pdf .pageContainer {
    margin-bottom: 50px; /* Add margin between pages */
  }
  .iDetail-back-container {
    width: 350px;
  }
  .iDetail-back-container .readmore {
    width: 100px;
    height: 30px;
    border-radius: 5px;
    font-size: 15px;
    line-height: 30px;
  }
}/*# sourceMappingURL=insightsdetail.styles.css.map */