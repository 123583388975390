.researcher {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 100%;
	& * {
		word-break: keep-all;
	}
	&-meet-background {
		z-index: 0;
		width: 100%;
		height: 1080px;
		background-color: black;
	}
	&-meet {
		width: 1320px;
		height: 783px;
		margin: auto;
		background: black;
		&-head {
			margin-top: 256px;
			margin-bottom: 75px;
			margin-left: -40px;
			color: #fff;
			/* Pre.Semi 60 */
			font-family: Pretendard;
			font-size: 60px;
			font-style: normal;
			font-weight: 600;
			line-height: 28px; /* 46.667% */
		}
	}
	&-body {
		display: flex;
		flex-direction: column;
		width: 1192px;
		margin: auto;
		margin-top: 150px;
		margin-bottom: 150px;
		&-head {
			width: 888px;
			color: #000;
			font-family: Pretendard;
			font-size: 30px;
			font-style: normal;
			font-weight: 600;
			line-height: 130%; /* 121.667% */
			//overflow-wrap: break-word;
			//word-break: keep-all;
		}
		&-search {
			display: flex;
			align-items: center;
			position: relative;
			width: 584px;
			height: 36px;
			margin-top: 70px;
			background: var(--Light-gray, #f0f0f0);

			//justify-content: space-between;
			input {
				width: 525px;
				margin-left: 16px;
				margin-right: 16px;
				background: var(--Light-gray, #f0f0f0);

				color: var(--Midium-gray, #9e9e9e);

				/* web. light.20 */
				font-family: Pretendard;
				font-size: 20px;
				font-style: normal;
				font-weight: 300;
				line-height: 28px; /* 140% */
			}
			img {
				position: absolute;
				right: 8px;
			}
		}
		&-list {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			column-gap: 24px;
			row-gap: 50px;
			overflow: hidden;

			width: 1192px;
			max-height: 1742px;
			margin-top: 20px;

			.researcher-info {
				display: flex;
				flex-direction: column;
				width: 280px;
				height: 398px;
				.researcher-info-box {
					position: absolute;

					width: inherit;
					height: 280px;
					div {
						opacity: 0;
					}
				}
				.researcher-info-box:hover {
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					background-color: rgba(60, 60, 60, 0.8);
					transition: 0.3s;
					div {
						width: 192px;
						height: 44px;
						opacity: 1;
						color: #fff;
						text-align: center;

						/* web. light.20 */
						font-family: Pretendard;
						font-size: 20px;
						font-style: normal;
						font-weight: 300;
						line-height: 45px; /* 140% */
						border: 1px solid white;
						border-radius: 10px;
					}
				}
				img {
					width: 280px;
					height: 280px;
					flex-shrink: 0;
					margin-bottom: 14px;
				}
				&-name {
					margin-bottom: 14px;
					color: #000;

					/* web. semi.30 */
					font-family: Pretendard;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					font-size: 30px;
					font-style: normal;
					font-weight: 600;
					line-height: 35px; /* 93.333% */
				}
				&-department {
					color: #000;
					font-family: Pretendard;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					font-size: 16px;
					font-style: normal;
					font-weight: 300;
					line-height: 28px; /* 175% */
					//margin-bottom: 4px;
				}
				&-project {
					@extend .researcher-info-department;
				}
			}
		}
		&-readmore {
			width: 186px;
			height: 44px;
			margin: auto;
			margin-top: 100px;
			border-radius: 10px;
			border: 1px solid var(--Dark-gray, #3c3c3c);
			background-color: white;
			color: var(--Dark-gray, #3c3c3c);
			text-align: center;
			/* web. light.20 */
			font-family: Pretendard;
			font-size: 20px;
			font-style: normal;
			font-weight: 300;
			line-height: 28px; /* 140% */
		}
		&-readmore:hover {
			cursor: pointer;
			background: var(--Light-gray, #f0f0f0);
		}
	}
}

@media (max-width: 1400px) {
	.researcher {
		&-meet-background {
			width: 100%;
			background-color: black;
			height: 900px;
			z-index: 0;
		}
		&-meet {
			width: 996.6px;
			height: 591px;
			&-head {
				margin-left: 0px;
			}
		}
		&-body {
			width: 900px;
			&-head {
				width: 670.46px;

				font-size: 22px;
			}
			&-search {
				width: 441px;
				height: 36px;

				input {
					width: 396px;
				}
			}
			&-list {
				column-gap: 18.1px;
				row-gap: 37.75px;
				width: 900px;
				max-height: 1315px;
				.researcher-info {
					width: 211.4px;
					height: 300.5px;
					.researcher-info-box {
						height: 211.4px;
					}
					.researcher-info-box:hover {
						div {
							width: 144px;
							height: 33px;

							font-size: 15px;

							line-height: 30px; /* 140% */

							border-radius: 10px;
						}
					}

					img {
						width: 211.4px;
						height: 210px;
						flex-shrink: 0;
					}
					&-name {
						font-size: 25px;

						margin-bottom: 5px;
					}
					&-department {
						font-size: 16px;

						line-height: 16px; /* 175% */
						//margin-bottom: 4px;
					}
					&-project {
						font-size: 12.44x;
						line-height: 28px; /* 175% */
					}
				}
			}
		}
	}
}

@media (max-width: 1023px) {
	.researcher {
		&-meet-background {
			z-index: 0;
			height: 700px;
		}
		&-meet {
			width: 751.3px;
			height: 459.66px;

			&-head {
				margin-top: 156px;

				margin-left: 0px;

				font-size: 45px;
			}
		}
		&-body {
			width: 700px;

			&-head {
				width: 521.47px;

				font-size: 17.1px;

				line-height: 36.5px; /* 121.667% */
			}
			&-search {
				width: 343px;
				height: 36px;

				input {
					width: 308px;

					font-size: 15.55px;
				}
			}
			&-list {
				column-gap: 14.08px;
				row-gap: 29.36px;
				width: 700px;
				max-height: 1022.78px;

				.researcher-info {
					width: 164.42px;
					height: 233.72px;
					.researcher-info-box {
						height: 164.42px;
					}
					.researcher-info-box:hover {
						div {
							width: 112px;
							height: 26px;

							font-size: 12px;

							line-height: 24px; /* 140% */

							border-radius: 7px;
						}
					}
					img {
						width: 164.42px;
						height: 163.33px;
						margin-bottom: 7px;
					}
					&-name {
						margin-top: 0px;
						margin-bottom: 10px;
						font-size: 15.3px;

						line-height: 1.3; /* 93.333% */
					}
					&-department {
						font-size: 12.44px;

						line-height: 1; /* 175% */
						//margin-bottom: 4px;
					}
					&-project {
						font-size: 12.44px;

						line-height: 1.6; /* 175% */
					}
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.researcher {
		&-meet-background {
			height: 400px;
		}
		&-meet {
			width: 350px;
			height: 262.66px;
			&-head {
				margin-top: 106px;
				margin-bottom: 25px;
				margin-left: 0px;
				font-size: 25px;
			}
		}
		&-body {
			width: 350px;
			margin-top: 50px;
			margin-bottom: 100px;
			&-head {
				width: 350px;
				height: 60px;
				font-size: 9.77px;
				line-height: 2; /* 121.667% */
			}
			&-search {
				width: 196px;
				height: 26px;
				margin-top: 20px;
				//justify-content: space-between;
				input {
					width: 308px;
					margin-left: 16px;

					font-size: 8.88px;
				}
				img {
					height: 15px;
					right: 8px;
				}
			}
			&-list {
				grid-template-columns: repeat(3, 1fr);

				column-gap: 10px;
				row-gap: 16.78px;
				margin-top: 20px;
				width: 350px;
				max-height: 584.44px;
				.researcher-info {
					width: 110px;
					height: 178px;
					.researcher-info-box {
						height: 110px;
					}
					.researcher-info-box:hover {
						div {
							width: 75px;
							height: 25px;

							font-size: 12px;

							line-height: 24px; /* 140% */

							border-radius: 6px;
						}
					}
					img {
						width: 110px;
						height: 110px;

						margin-bottom: 4px;
					}
					&-name {
						font-size: 15px;

						line-height: 1.5; /* 93.333% */
						margin-bottom: 3px;
					}
					&-department {
						font-size: 12px;

						line-height: 1; /* 175% */
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						//margin-bottom: 4px;
					}
					&-project {
						font-size: 12px;

						line-height: 1.5; /* 175% */
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
			}
			&-readmore {
				width: 80px;
				height: 20px;
				margin-top: 50px;
				border-radius: 6px;
				font-size: 12px;
				line-height: 1.5;
			}
		}
	}
}
