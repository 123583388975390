@mixin font-setting($color, $font-weight: normal, $font-size, $line-height) {
	color: $color;

	font-size: $font-size;

	font-family: Pretendard;

	@if $font-weight != normal {
		font-weight: $font-weight;
	}

	font-stretch: normal;

	font-style: normal;

	line-height: $line-height;

	letter-spacing: normal;
}

.footer {
	width: 1192px;

	height: 814px;

	margin: auto;
	& * {
		word-break: keep-all;
	}
	&-news {
		width: 100%;

		height: 166px;

		margin-bottom: 131px;

		&-head {
			margin-bottom: 24px;

			color: #000;

			font-family: Pretendard;

			font-size: 30px;

			font-style: normal;

			font-weight: 600;

			line-height: 28px; /* 93.333% */
		}

		form {
			width: 1192px;

			height: 44px;

			margin-bottom: 16px;

			input {
				width: 987px;

				height: 44px;

				margin-right: 21px;

				padding-left: 21px;
				padding-right: 21px;
				border-radius: 10px;

				border: 1px solid var(--Dark-gray, #3c3c3c);

				@include font-setting(black, 300, 20px, 28px);
			}

			.button {
				border-radius: 10px;
				width: 184px;
				height: 44px;
				background: var(--Dark-gray, #3c3c3c);

				@include font-setting(#3c3c3c, 300, 20px, 28px);
			}
			.button-active {
				@extend .button;
			}
			.button-active:hover {
				cursor: pointer;
				background-color: black;
			}
		}

		&-please {
			width: 762px;

			color: var(--Midium-gray, #9e9e9e);

			font-family: Pretendard;

			font-size: 22px;

			font-style: normal;

			font-weight: 400;

			line-height: 28px; /* 127.273% */
		}
	}

	&-info {
		display: flex;

		flex-direction: column;

		&-head {
			display: flex;

			flex-direction: row;

			height: 20px;

			margin-bottom: 40px;

			color: #000;

			//text-align: center;

			font-family: Pretendard;

			font-size: 22px;

			font-style: normal;

			font-weight: 600;

			line-height: 28px; /* 127.273% */

			text-decoration-line: underline;

			.legal {
				margin-right: 29px;
			}
		}

		&-beamworks {
			margin-bottom: 20px;

			color: #000;

			font-family: Pretendard;

			font-size: 22px;

			font-style: normal;

			font-weight: 400;

			line-height: 28px; /* 127.273% */
		}

		&-ceo {
			display: flex;

			flex-direction: row;

			margin-bottom: 20px;

			.ceo {
				margin-right: 21px;
				color: #000;
				font-family: Pretendard;
				font-size: 22px;
				font-style: normal;
				font-weight: 600;
				line-height: 28px; /* 127.273% */
			}

			.kim {
				color: #000;
				font-family: Pretendard;
				font-size: 22px;
				font-style: normal;
				font-weight: 400;
				line-height: 28px; /* 127.273% */
			}
			.brtext {
				@extend .ceo;
				margin-left: 45px;
			}
			.brnumber {
				@extend .kim;
			}
		}

		&-address {
			display: flex;

			flex-direction: row;

			margin-bottom: 20px;

			.business {
				margin-right: 21px;

				color: #000;

				font-family: Pretendard;

				font-size: 20px;

				font-style: normal;

				font-weight: 600;

				line-height: 28px; /* 127.273% */
			}

			.location {
				color: #000;

				font-family: Pretendard;

				font-size: 20px;

				font-style: normal;

				font-weight: 400;

				line-height: 28px; /* 127.273% */
			}
		}

		&-number {
			display: flex;

			flex-direction: row;

			.number1 {
				margin-right: 21px;

				font-family: Pretendard;

				font-size: 20px;

				font-style: normal;

				font-weight: 600;

				line-height: 28px; /* 127.273% */
			}

			.number2 {
				margin-right: 49px;

				color: #000;

				font-family: Pretendard;

				font-size: 20px;
				font-style: normal;

				font-weight: 400;

				line-height: 28px; /* 127.273% */
			}

			.email1 {
				font-family: Pretendard;

				font-size: 20px;

				font-style: normal;

				font-weight: 600;

				margin-right: 21px;

				line-height: 28px; /* 127.273% */
			}

			.email2 {
				margin-right: 65px;

				color: #000;

				font-family: Pretendard;

				font-size: 20px;

				font-style: normal;

				font-weight: 400;

				line-height: 28px; /* 127.273% */
			}

			.fax1 {
				margin-right: 21px;

				font-family: Pretendard;

				font-size: 20px;

				font-style: normal;

				font-weight: 600;

				line-height: 28px; /* 127.273% */
			}

			.fax2 {
				color: #000;

				font-family: Pretendard;

				font-size: 20px;

				font-style: normal;

				font-weight: 400;

				line-height: 28px; /* 127.273% */
			}
		}

		&-logo {
			display: flex;

			flex-direction: row;

			align-items: center;

			margin-top: 30px;

			.beamworks {
				margin-right: 31px;

				margin-top: -2px;
			}
		}
	}
}

@media (max-width: 1400px) {
	.footer {
		width: 900px;

		height: 800px;

		&-news {
			width: 900px;

			form {
				width: 900px;

				input {
					width: 750px;

					margin-right: 20px;
				}

				.button {
					width: 130px;
				}
			}

			&-please {
				width: 900px;
			}
		}

		&-info {
			width: 900px;

			&-address {
				.business {
					font-size: 15px;
				}

				.location {
					font-size: 15px;
				}
			}

			&-number {
				.number1 {
					font-size: 15px;
				}

				.number2 {
					font-size: 15px;
				}

				.email1 {
					font-size: 15px;
				}

				.email2 {
					font-size: 15px;
				}

				.fax1 {
					font-size: 15px;
				}

				.fax2 {
					font-size: 15px;
				}
			}
		}
	}
}

@media (max-width: 1023px) {
	.footer {
		width: 700px;

		height: 600px;

		&-news {
			width: 700px;

			&-head {
				font-size: 25px;
			}

			form {
				width: 700px;

				input {
					width: 550px;

					margin-right: 15px;
				}

				.button {
					width: 130px;
				}
			}

			&-please {
				width: 700px;
			}
		}

		&-info {
			width: 700px;

			&-head {
				margin-bottom: 20px;

				font-size: 17px;
			}

			&-beamworks {
				margin-bottom: 0px;

				font-size: 12px;
			}

			&-ceo {
				margin-bottom: 0px;

				.ceo {
					font-size: 12px;
				}

				.kim {
					font-size: 12px;
				}
			}

			&-address {
				margin-bottom: 0px;

				.business {
					font-size: 12px;
				}

				.location {
					font-size: 12px;
				}
			}

			&-number {
				.number1 {
					font-size: 12px;
				}

				.number2 {
					font-size: 12px;
				}

				.email1 {
					font-size: 12px;
				}

				.email2 {
					font-size: 12px;
				}

				.fax1 {
					font-size: 12px;
				}

				.fax2 {
					font-size: 12px;
				}
			}

			&-logo {
				.beamworks {
					font-size: 15px;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.footer {
		width: 350px;

		height: 500px;

		&-news {
			width: 350px;

			margin-bottom: 50px;

			&-head {
				margin-bottom: 15px;

				font-size: 20px;
			}

			form {
				width: 350px;

				input {
					width: 240px;

					margin-right: 10px;
					height: 35px;
					font-size: 12px;
				}

				.button {
					width: 100px;
					height: 35px;
					font-size: 12px;
				}
			}

			&-please {
				width: 350px;

				font-size: 12px;

				line-height: 1.2;
			}
		}

		&-info {
			display: grid;

			grid-template-columns: 100px 100px 150px;

			grid-template-rows: 0.5fr 0.3fr 1fr 1.3fr 1fr;

			width: 350px;

			&-head {
				grid-area: head;

				font-size: 15px;
			}

			&-beamworks {
				grid-area: beamworks;

				flex-direction: column;

				flex-wrap: wrap;

				height: 20px;

				margin-bottom: 0px;

				font-size: 11px;
			}

			&-ceo {
				grid-area: ceo;
				display: grid;
				grid-template-columns: 0.5fr 2.5fr;
				margin-bottom: 0px;

				.ceo {
					font-size: 11px;
					grid-area: cceo;
					//margin-right: -10px;
				}

				.kim {
					font-size: 11px;
					grid-area: ckim;
					margin-left: 0px;
				}
				.brtext {
					//display: none;
					font-size: 11px;
					grid-area: ctext;
					margin-left: 0;
				}
				.brnumber {
					//display: none;
					font-size: 11px;
					grid-area: cnumber;
				}
				grid-template-areas:
					'cceo ckim'
					'ctext ctext'
					'cnumber cnumber';
			}

			&-address {
				grid-area: address;

				flex-direction: column;

				margin-bottom: 0px;

				.business {
					margin-bottom: 3px;

					font-size: 11px;
				}

				.location {
					font-size: 11px;

					line-height: 1.6;
				}
			}

			&-number {
				margin-left: 5px;

				grid-area: number;

				flex-direction: column;

				.number1 {
					margin-bottom: -2px;

					font-size: 10.7px;
				}

				.number2 {
					font-size: 12px;
				}

				.email1 {
					margin-bottom: -5px;

					font-size: 11px;
				}

				.email2 {
					font-size: 12px;
					margin-bottom: 5px;
				}

				.fax1 {
					margin-bottom: -5px;

					font-size: 11px;
				}

				.fax2 {
					font-size: 12px;
				}
			}

			&-logo {
				margin-top: 0px;
				margin-bottom: 30px;
				align-self: end;

				grid-area: logo;
			}

			grid-template-areas:
				'head head head'
				'beamworks beamworks number'
				'ceo ceo number'
				'address address number'
				'logo logo logo';
		}
	}
}
