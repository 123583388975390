.error404 {
	width: 60vw;
	height: fit-content;
	margin: auto;
	//margin-bottom: 10vw;
	display: flex;
	flex-direction: column;
	&-image {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 60vw;
		position: relative;
		//margin-top: 10vw;
		&-number {
			width: 50vw;
		}
		&-logo {
			position: absolute;
			right: 10vw;
			top: 7vw;
			width: 10vw;
			height: 10vw;
		}
	}
	&-text {
		//margin-top: 5vw;
		font-size: 1vw;
		text-align: center;
	}
}
