* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: Pretendard;
  list-style: none;
  text-decoration: none;
}

// html {
//   font-size: 16px;
// }
