@mixin font-setting($color, $font-weight: normal, $font-size, $line-height) {
	color: $color;
	font-size: $font-size;
	font-family: Pretendard;
	@if $font-weight != normal {
		font-weight: $font-weight;
	}
	font-stretch: normal;
	font-style: normal;
	line-height: $line-height;
	letter-spacing: normal;
}

.gnb {
	display: flex;
	width: 100%;
	height: 77px;
	background: rgba(60, 60, 60, 0.8);
	backdrop-filter: blur(5px);
	display: flex;
	align-items: center;
	flex-direction: row;
	position: fixed;
	z-index: 100;
	&-newlogo {
		position: absolute;
		left: 39px;
		width: 10.6%;
		&:hover {
			cursor: pointer;
		}
	}
	&-logo {
		position: absolute;
		width: 6.4%;
		left: 14.3%;
	}
	&-logo:hover {
		cursor: pointer;
	}
	&-bar {
		display: none;
		position: absolute;
		left: 21px;
	}
	&-bar:hover {
		cursor: pointer;
	}
	&-menu {
		width: 554px;
		height: 31px;
		position: absolute;
		left: 28.8%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		@include font-setting(white, 300, 20px, 32px);
		div:hover {
			text-decoration: underline;
			cursor: pointer;
			transition: 0.25s;
		}
	}
	.gnb-tiny {
		position: absolute;
		width: 2.7%;
		left: 21%;
		top: 17px;
		color: var(--Dark-gray, white);
		text-align: center;
		font-family: Pretendard;
		font-size: 16px;
		font-style: normal;
		font-weight: 300;
		line-height: 28px; /* 175% */
		&:hover {
			cursor: pointer;
		}
	}
	.side {
		position: fixed;
		display: none;
		//position: absolute;
		margin-top: 158vh;
		width: 208px;
		height: 150vh;
		background: rgba(0, 0, 0, 0.6);
		z-index: 200;
		.side-menu {
			//position: absolute;
			width: 208px;
			height: 150vh;

			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			align-items: center;

			.side-menu-button {
				//position: absolute;
				display: flex;
				align-items: center;
				height: 60px;

				//text-align: center;
				color: #fff;
				//text-align: center;

				/* web. light.20 */
				font-family: Pretendard;
				font-size: 20px;
				font-style: normal;
				font-weight: 300;

				width: 208px !important;
				z-index: 1000 !important;
				border-bottom: 0.5px solid rgba(255, 255, 255, 0.6);
				div {
					margin-left: 18px;
				}
			}
			.side-menu-button:hover {
				cursor: pointer;
			}
		}
	}

	&-En {
		position: absolute;
		right: 271px;
		width: 122px;
		height: 44px;
		border-radius: 9px;
		display: flex;
		background: var(--Midium-gray, #9e9e9e);
		transition: 0.5s ease;

		.kr-btn {
			width: 61px;
			height: 44px;
			background: var(--Midium-gray, #9e9e9e);
			border-radius: 7px;
			@include font-setting(#f0f0f0, 300, 20px, 2.3);
			text-align: center;
		}
		.en-btn {
			@extend .kr-btn;
			border-radius: 7px;
			background: var(--Light-gray, #f0f0f0);
			@include font-setting(black, 300, 20px, 2.3);
		}
	}
	&-En:hover {
		cursor: pointer;
	}
	&-Kr {
		position: absolute;
		transition: 0.5s ease;
		right: 271px;
		width: 122px;
		height: 44px;
		border-radius: 9px;
		display: flex;
		background: var(--Light-gray, #9e9e9e);

		.en-btn {
			width: 61px;
			height: 44px;
			border-radius: 7px;
			background: var(--Light-gray, #9e9e9e);
			@include font-setting(#f0f0f0, 300, 20px, 2.3);
			text-align: center;
		}
		.kr-btn {
			@extend .en-btn;
			border-radius: 7px;
			background: var(--Dark-gray, #f0f0f0);
			@include font-setting(black, 300, 20px, 2.3);
		}
	}
	&-Kr:hover {
		cursor: pointer;
	}
	&-join {
		display: flex;
		width: 158px;
		height: 44px;
		position: absolute;
		right: 60px;

		justify-content: center;
		align-items: center;
		border-radius: 10px;
		background: var(--Light-gray, #f0f0f0);
		@include font-setting(black, 300, 20px, 2.3);
	}
	&-join:hover {
		cursor: pointer;
		background: var(--Light-gray-Hovered, #e1e1e1);
	}
	&-language {
		display: none;
		position: absolute;
		right: 30px;
	}
	&-language:hover {
		cursor: pointer;
	}
	.globeBox {
		display: none;
		position: absolute;
		right: 30px;
		top: 48px;
		width: 51px;
		height: 60px;
		border: 0.5px solid var(--Light-gray, #f0f0f0);
		background-color: black;

		&-kr {
			height: 30px;
			color: #fff;
			font-family: Pretendard;
			font-size: 16px;
			font-style: normal;
			font-weight: 300;
			line-height: 30px;
			text-align: center;
		}
		&-en {
			@extend .globeBox-kr;
			border-bottom: 0.5px solid var(--Light-gray, #f0f0f0);
		}
	}
	.globeBox:hover {
		cursor: pointer;
	}
}

.gnb-white {
	@extend .gnb;
	background: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(5px);
	.gnb-menu {
		color: #000;
		text-align: center;

		/* web. light.20 */
		font-family: Pretendard;
		font-size: 20px;
		font-style: normal;
		font-weight: 300;
		line-height: 28px; /* 140% */
	}
	.gnb-tiny {
		color: var(--Dark-gray, #3c3c3c);
		text-align: center;
		font-family: Pretendard;
		font-size: 16px;
		font-style: normal;
		font-weight: 300;
		line-height: 28px; /* 175% */
	}
}

@media (max-width: 1400px) {
	.gnb {
		width: 100%;
		height: 77px;
		background: rgba(60, 60, 60, 0.8);
		backdrop-filter: blur(5px);
		display: flex;
		align-items: center;
		flex-direction: row;
		position: fixed;
		z-index: 100;
		&-logo {
			left: 15.5%;
			//width: 110px;
		}
		&-menu {
			width: 454px;
			height: 31px;
			left: 28%;
			@include font-setting(white, 300, 15px, 32px);
		}
		.gnb-tiny {
			display: none;
		}
		&-En {
			right: 151px;
			width: 60px;
			height: 25px;
			border-radius: 5px;

			.kr-btn {
				width: 30px;
				height: 25px;
				@include font-setting(white, 300, 12px, 2.3);
			}
			.en-btn {
				border-radius: 5px;
				@include font-setting(black, 300, 12px, 2.3);
			}
		}
		&-Kr {
			right: 151px;
			width: 60px;
			height: 25px;
			border-radius: 5px;

			.en-btn {
				width: 30px;
				height: 25px;
				border-radius: 6px;
				@include font-setting(white, 300, 12px, 2.3);
			}
			.kr-btn {
				border-radius: 6px;
				@include font-setting(black, 300, 12px, 2.3);
			}
		}
		&-join {
			width: 68px;
			height: 25px;
			right: 50px;

			border-radius: 5px;
			@include font-setting(black, 300, 12px, 2.3);
		}
	}

	.gnb-white {
		background: rgba(255, 255, 255, 0.8);
		backdrop-filter: blur(5px);
		.gnb-menu {
			color: #000;
			text-align: center;

			/* web. light.20 */
			font-family: Pretendard;
			font-size: 15px;
			font-style: normal;
			font-weight: 300;
			line-height: 28px; /* 140% */
		}
		.gnb-tiny {
			display: none;
		}
	}
}

@media (max-width: 1023px) {
	.gnb {
		width: 100%;
		height: 57px;
		background: rgba(60, 60, 60, 0.8);
		backdrop-filter: blur(5px);
		display: flex;
		align-items: center;
		flex-direction: row;
		position: fixed;
		z-index: 100;
		&-newlogo {
			left: 30px;
			width: 80px;
		}
		&-logo {
			left: 130px;
			width: 80px;
		}
		&-menu {
			width: 304px;
			height: 31px;
			left: 244px;
			@include font-setting(white, 300, 13px, 32px);
		}
		.side {
			margin-top: 156vh;
		}
		&-En {
			right: 131px;
			width: 60px;
			height: 25px;
			border-radius: 6px;

			.kr-btn {
				width: 30px;
				height: 25px;
				@include font-setting(white, 300, 11px, 2.5);
			}
			.en-btn {
				border-radius: 6px;
				@include font-setting(black, 300, 11px, 2.5);
			}
		}
		&-Kr {
			right: 131px;
			width: 60px;
			height: 25px;
			border-radius: 6px;

			.en-btn {
				width: 30px;
				height: 25px;
				border-radius: 6px;
				@include font-setting(white, 300, 11px, 2.5);
			}
			.kr-btn {
				border-radius: 6px;
				@include font-setting(black, 300, 11px, 2.5);
			}
		}
		&-join {
			width: 68px;
			height: 25px;
			right: 30px;

			border-radius: 7px;
			@include font-setting(black, 300, 12px, 2.3);
		}
	}

	.gnb-white {
		background: rgba(255, 255, 255, 0.8);
		backdrop-filter: blur(5px);
		.gnb-menu {
			color: #000;
			text-align: center;

			/* web. light.20 */
			font-family: Pretendard;
			font-size: 13px;
			font-style: normal;
			font-weight: 300;
			line-height: 28px; /* 140% */
		}
	}
}

@media (max-width: 767px) {
	.gnb {
		width: 100%;
		height: 57px;
		background: rgba(60, 60, 60, 0.8);
		backdrop-filter: blur(5px);
		display: flex;
		align-items: center;
		flex-direction: row; /* 주석 해제해서 활성화 */
		position: fixed;
		z-index: 100;
		justify-content: center;
		&-newlogo {
			display: none;
		}
		&-logo {
			position: relative;
			left: 0px;
		}
		&-bar {
			display: block;
		}
		.side {
			display: block;
			position: absolute;
			left: 0px;
			//position: absolute;
			// margin-top: 142vh;
			// width: 208px;
			// height: 150vh;
			// background-color: rgba(0, 0, 0, 0.3);
			// z-index: 200;
			// .side-menu {
			// 	//position: absolute;
			// 	width: 208px;
			// 	height: 150vh;

			// 	display: flex;
			// 	flex-wrap: wrap;
			// 	flex-direction: column;
			// 	align-items: center;

			// 	.side-menu-button {
			// 		//position: absolute;
			// 		border: 2px solid black !important;
			// 		height: 50px;
			// 		color: blue !important;
			// 		width: 200px !important;
			// 		z-index: 1000 !important;
			// 	}
			// }
		}
		&-menu {
			display: none;
			width: 304px;
			height: 31px;
			left: 144px;
			@include font-setting(white, 300, 13px, 32px);
		}

		&-En {
			display: none;
		}
		&-Kr {
			display: none;
		}
		&-join {
			display: none;
		}
		&-language {
			display: block;
		}
		.globeBox {
			display: block;
		}
	}

	.gnb-white {
		background: rgba(255, 255, 255, 0.8);
		//background-color: black;
		backdrop-filter: blur(5px);
		.gnb-menu {
			color: #000;
			text-align: center;

			/* web. light.20 */
			font-family: Pretendard;
			font-size: 13px;
			font-style: normal;
			font-weight: 300;
			line-height: 28px; /* 140% */
		}
	}
}
