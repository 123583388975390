@mixin font-setting($color, $font-weight: normal, $font-size, $line-height) {
	color: $color;
	font-size: $font-size;
	font-family: Pretendard;
	@if $font-weight != normal {
		font-weight: $font-weight;
	}
	font-stretch: normal;
	font-style: normal;
	line-height: $line-height;
	letter-spacing: normal;
}

.orca {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	& * {
		word-break: keep-all;
	}
	&-what-container {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 37.9%;
		z-index: 50;
		.orca-banner {
			position: absolute;
			z-index: 0;
			width: 100%;
			height: 727px;
			height: 100%;
		}
		.banner-curtain {
			position: absolute;
			z-index: 10;
			width: 100%;
			background: linear-gradient(90deg, #000 26.98%, rgba(0, 0, 0, 0) 100%);
			height: 100%;
		}
	}
	&-what {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 1192px;
		height: 37.8vw;
		margin: auto;
		z-index: 20;
		//background: linear-gradient(90deg, #000 26.98%, rgba(0, 0, 0, 0) 100%);
		&-box {
			position: absolute;
			width: 948px;
			height: 364px;
			color: white;
			display: grid;
			grid-template-columns: 1fr 2.02fr;
			grid-template-rows: 1.5fr 1fr;
			bottom: 100px;
			//margin-left: 18.9583%;
			white-space: pre-wrap;

			&-head {
				align-self: end;
				@include font-setting(white, 600, 60px, 70px);
				// width: 90%;
				grid-area: head;
				margin-bottom: 42px;
			}
			&-about {
				@include font-setting(white, 400, 22px, 28px);
				//width: 230px;
				height: 28px;
				grid-area: about;
			}
			&-body {
				@include font-setting(white, 600, 30px, 36.5px);
				grid-area: body;
				width: 644px;
				height: 106px;
			}

			grid-template-areas:
				'head head'
				'about body';
		}
	}
	&-goal {
		width: 1192px;
		height: 210px;
		margin: auto;
		margin-top: 150px;
		position: relative;
		display: flex;
		flex-direction: column;
		&-box {
			width: 1040px;
			height: 210px;
			display: grid;
			grid-template-rows: 98px 112px;
			grid-template-columns: 456px 584px;
			position: relative;
			&-head {
				@include font-setting(black, 600, 60px, 28px);
				grid-area: head;
				width: 668px;
				height: 28px;
			}
			&-body1 {
				@include font-setting(black, 400, 22px, 28px);
				width: 432px;
				height: 112px;
				grid-area: body1;
			}
			&-body2 {
				@include font-setting(black, 400, 22px, 28px);
				width: 584px;
				height: 84px;

				grid-area: body2;
			}
			grid-template-areas:
				'head head'
				'body1 body2';
		}
	}
	&-benefits {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 1192px;
		height: 664px;
		margin: auto;
		margin-top: 150px;
		margin-bottom: 150px;
		&-box {
			display: flex;
			flex-direction: column;
			width: 1192px;
			height: 664px;

			//margin-left: 152px;
			&-head {
				margin-bottom: 70px;
				color: #000;
				font-family: Pretendard;
				font-size: 60px;
				font-style: normal;
				font-weight: 600;
				line-height: 28px; /* 46.667% */
			}
			&-body1 {
				display: grid;
				grid-template-columns: 310px 800px;
				grid-template-rows: 52px 84px;
				width: 1110px;
				height: 136px;

				margin-bottom: 80px;
				&-square {
					grid-area: graybox;
					width: 280px;
					height: 136px;
					//background: var(--Light-gray, #f0f0f0);
				}
				&-head {
					grid-area: head;
					width: 800px;
					height: 28px;
					color: #000;
					/* web. semi.30 */
					font-family: Pretendard;
					font-size: 30px;
					font-style: normal;
					font-weight: 600;
					line-height: 28px; /* 93.333% */
				}
				&-body {
					grid-area: body;
					width: 432px;
					height: 84px;
					color: #000;
					/* web. re.22 */
					font-family: Pretendard;
					font-size: 22px;
					font-style: normal;
					font-weight: 400;
					line-height: 28px; /* 127.273% */
				}
				grid-template-areas:
					'graybox head'
					'graybox body';
			}
			&-body2 {
				@extend .orca-benefits-box-body1;
				&-square {
					@extend .orca-benefits-box-body1-square;
				}
				&-head {
					@extend .orca-benefits-box-body1-head;
					width: 559px;
				}
				&-body {
					@extend .orca-benefits-box-body1-body;
				}
			}
			&-body3 {
				@extend .orca-benefits-box-body1;
				&-square {
					@extend .orca-benefits-box-body1-square;
				}
				&-head {
					@extend .orca-benefits-box-body1-head;
					width: 784px;
				}
				&-body {
					@extend .orca-benefits-box-body1-body;
				}
			}
		}
	}
}

@media (max-width: 1400px) {
	.orca {
		justify-content: center;
		&-what {
			width: 900px;
			margin-bottom: 0px;

			&-box {
				width: 771px;
				height: 250px;
				grid-template-rows: 1fr 1.8fr;
				bottom: 30px;
				//margin-bottom: 50px;
				&-head {
					font-size: 35px;
					margin-bottom: 10px;
					line-height: 45px;
				}
				&-about {
					font-size: 20px;
					margin-top: 20px;
				}
				&-body {
					width: 542px;
					margin-top: 20px;
					@include font-setting(white, 600, 25px, 30px);
				}
			}
		}
		&-goal {
			width: 900px;
			margin-top: 100px;
			&-box {
				width: 771px;
				height: 250px;
				//grid-template-columns: 1fr 2fr;
				grid-template-rows: 1fr 1.8fr;
				&-head {
					font-size: 35px;
				}
				&-body1 {
					width: 400px;
				}
				&-body2 {
					width: 440px;
					margin-left: 0px;
				}
			}
		}
		&-benefits {
			width: 900px;
			&-box {
				width: 900px;
				margin-left: 0px;
				&-head {
					font-size: 35px;
				}
				&-body1 {
					width: 900px;
					margin-bottom: 20px;

					&-head {
						font-size: 23px;
						width: 650px;
					}
					&-body {
						font-size: 18px;
						width: 350px;
					}
				}
			}
		}
	}
}

@media (max-width: 1023px) {
	.orca {
		//justify-content: center;
		&-what-container {
			.orca-banner {
			}
			.banner-curtain {
			}
		}
		&-what {
			width: 700px;
			//height: 500px;
			&-box {
				width: 600px;
				height: 200px;
				grid-template-columns: 170px 430px;
				grid-template-rows: 100px 100px;
				margin-bottom: 0px;
				&-head {
					align-self: center;
					//justify-self: center;
					@include font-setting(white, 600, 30px, 45px);
					line-height: 40px;
				}
				&-about {
					font-size: 16px;
					margin-top: 0px;
				}
				&-body {
					width: 500px;
					margin-top: 0px;
					@include font-setting(white, 600, 19px, 28px);
				}
			}
		}
		&-goal {
			width: 700px;
			margin-top: 70px;
			&-box {
				display: flex;
				flex-direction: column;
				width: 600px;
				height: 230px;
				grid-template-rows: 50px 95px 95px;

				//background-color: blue;
				&-head {
					@include font-setting(black, 600, 30px, 28px);
				}
				&-body1 {
					width: 600px;
					margin-top: 40px;
					@include font-setting(black, 600, 16px, 28px);
				}
				&-body2 {
					width: 600px;
					margin-top: 0px;
					@include font-setting(black, 600, 16px, 28px);
				}
			}
		}
		&-benefits {
			width: 700px;
			&-box {
				width: 700px;
				margin-left: 0px;
				&-head {
					@include font-setting(black, 600, 45px, 28px);
				}
				&-body1 {
					grid-template-columns: 220px 480px;
					grid-template-rows: 35px 55px;
					width: 700px;
					margin-bottom: 20px;
					&-square {
						width: 200px;
						height: 100px;
						// width: 90px;
						// height: 90px;
					}
					&-head {
						font-size: 18px;
						width: 480px;
						line-height: 1.1;
					}
					&-body {
						font-size: 15px;
						width: 300px;
						line-height: 1.2;
					}
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.orca {
		justify-content: center;
		&-what-container {
			height: 300px;
			.orca-banner {
				height: 300px;
				width: 767px;
				overflow: hidden;
				//position: relative;
				//display: none;
				//left: -90px;
			}
			.banner-curtain {
				height: 300px;
				background: linear-gradient(
					90deg,
					rgba(0, 0, 0, 0.8) 10%,
					rgba(0, 0, 0, 0) 100%
				);
			}
		}
		&-what {
			width: 350px;
			height: 200px;
			margin-top: 70px;
			&-box {
				display: flex;
				flex-direction: column;
				height: fit-content;
				margin-top: 80px;
				width: 300px;
				margin-top: 0px;
				margin-bottom: 0px;
				&-head {
					@include font-setting(white, 600, 19px, 28px);
					align-self: flex-start;
					height: fit-content;
				}
				&-about {
					margin-top: 0px;
					font-size: 14px;
					height: fit-content;
				}
				&-body {
					width: 300px;
					margin-top: 10px;
					@include font-setting(white, 600, 12px, 20px);
					height: fit-content;
				}
			}
		}
		&-goal {
			margin-top: 50px;
			width: 350px;
			height: 250px;
			&-box {
				display: flex;
				flex-direction: column;
				margin-bottom: 0px;
				width: 300px;
				height: 250px;
				&-head {
					width: 300px;
					@include font-setting(black, 600, 19px, 24px);
				}
				&-body1 {
					width: 350px;
					height: fit-content;
					margin-top: 30px;
					@include font-setting(black, 600, 12px, 18px);
				}
				&-body2 {
					width: 350px;

					margin-top: 20px;
					@include font-setting(black, 600, 12px, 18px);
				}
			}
		}
		&-benefits {
			width: 350px;
			height: 550px;
			margin-top: 0px;
			margin-bottom: 0px;
			&-box {
				width: 350px;
				margin-left: 0px;
				&-head {
					@include font-setting(black, 600, 25px, 28px);
					margin-bottom: 40px;
				}
				&-body1 {
					grid-template-columns: 100px 250px;
					grid-template-rows: 45px 55px;
					margin-bottom: 0px;
					width: 300px;
					&-square {
						width: 90px;
						height: 90px;
					}
					&-head {
						width: 250px;
						font-size: 13px;
						line-height: 1.4;
					}
					&-body {
						width: 200px;
						font-size: 11px;
						line-height: 16px;
					}
				}
			}
		}
	}
}
