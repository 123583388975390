@mixin font-setting($color, $font-weight: normal, $font-size, $line-height) {
	color: $color;
	font-size: $font-size;
	font-family: Pretendard;
	@if $font-weight != normal {
		font-weight: $font-weight;
	}
	font-stretch: normal;
	font-style: normal;
	line-height: $line-height;
	letter-spacing: normal;
}
.home {
	width: 100%;
	display: flex;
	flex-direction: column;
	& * {
		word-break: keep-all;
	}
	&-anime {
		//animation section
		width: 100%;
		height: 100%;
		//height: fit-content;
		background-color: black;
		//margin-top: 77px;
		display: flex;
		align-items: center;
		position: relative;
		justify-content: center;
		video {
			width: 100%;
			margin-top: 77px;
		}
	}
	.intro-background {
		width: 100%;
		background-color: black;
	}
	&-intro {
		//intro section
		width: 1192px;
		height: 1272px;

		background-color: black;
		margin: auto;
		position: relative;
		display: flex;
		flex-direction: column;
		&-what {
			margin-top: 55px;
			width: 948px;
			height: 364px;
			color: white;
			display: grid;
			grid-template-columns: 1fr 2.02fr;
			grid-template-rows: 1.5fr 1fr;
			//margin-top: 0px;
			//margin-left: 18.9583%;
			position: relative;
			&-head {
				align-self: end;
				@include font-setting(white, 600, 60px, 70px);
				grid-area: head;
				margin-bottom: 42px;
				overflow-wrap: break-word;
				hyphens: auto;
				//word-wrap: break-word;
			}
			&-about {
				@include font-setting(white, 400, 22px, 28px);
				//width: 230px;
				height: 28px;
				grid-area: about;
			}
			&-body {
				@include font-setting(white, 600, 30px, 36.5px);
				grid-area: body;
				width: 644px;
				height: 106px;
				//word-break: break-all;
				word-wrap: break-word;
			}

			grid-template-areas:
				'head head'
				'about body';
		}
		&-goal {
			width: 1040px;
			height: 298px;
			display: grid;
			grid-template-rows: 98px 116px 84px;
			position: relative;
			margin-top: 150px;
			//margin-left: 18.9583%;

			&-head {
				@include font-setting(white, 600, 60px, 28px);
				grid-area: head;
				width: 668px;
				height: 28px;
			}
			&-body1 {
				@include font-setting(white, 400, 22px, 28px);
				width: 584px;
				height: 84px;
				grid-area: body1;
				justify-self: end;
			}
			&-body2 {
				@extend .home-intro-goal-body1;
				grid-area: body2;
			}
			grid-template-areas:
				'head'
				'body1'
				'body2';
		}
		&-contact {
			color: white;
			position: relative;
			width: 1192px;
			height: 160px;
			margin-top: 150px;
			//margin-left: 18.9583%;
			&-head {
				@include font-setting(white, 600, 30px, 28px);
				margin-bottom: 16px;
			}
			&-email {
				background-color: black;
				width: 1192px;
				height: 44px;
				input {
					width: 987px;
					height: 44px;
					border-radius: 10px;
					border: 1px solid var(--Light-gray, #f0f0f0);
					background-color: black;
					@include font-setting(#f0f0f0, 300, 20px, 28px);
					padding-left: 21px;
					padding-right: 21px;
					margin-right: 21px;
				}
				.button {
					border-radius: 10px;
					width: 184px;
					height: 44px;
					background: var(--Light-gray, #f0f0f0);
					@include font-setting(#3c3c3c, 300, 20px, 28px);
				}
				.button-active {
					@extend .button;
				}
				.button-active:hover {
					cursor: pointer;
					background: var(--Light-gray, #e1e1e1);
				}
				margin-bottom: 16px;
			}
			&-body {
				@include font-setting(#9e9e9e, 400, 22px, 28px);
			}
		}
	}

	&-research {
		position: relative;
		width: 1192px;
		height: 824px;
		background-color: white;
		margin-bottom: 150px;
		margin: auto;

		&-head {
			position: relative;
			margin-top: 142px;
			//margin-left: 18.9583%;
			width: 1192px;
			height: 46px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			span {
				@include font-setting(black, 600, 60px, 28px);
			}
			button {
				width: 161px;
				height: 44px;
				background: var(--Light-gray, #fff);
				border-radius: 10px;
				border: 1px solid var(--Dark-gray, #3c3c3c);
				@include font-setting(#3c3c3c, 300, 20px, 28px);
			}
			button:hover {
				cursor: pointer;
				background-color: #f0f0f0;
			}
		}
	}
	&-orcapop {
		display: flex;
		justify-content: center;
		//align-items: center;
		position: fixed;
		z-index: 30;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);

		backdrop-filter: blur(8.5px);
		&-box {
			width: 32.3vw;
			height: 7.4vw;
			margin-top: 23vw;
			border-radius: 0.625vw;
			background: var(--Light-gray, #f0f0f0);
			display: inline-flex;
			padding: 1.04vw;
			flex-direction: column;
			align-items: center;
			gap: 0.6vw;
			.text {
				color: #000;
				text-align: center;
				/* web. light.20 */
				font-family: Pretendard;
				font-size: 1.04vw;
				font-style: normal;
				font-weight: 300;
				line-height: 1.4; /* 140% */
			}
			button {
				display: flex;
				width: 30.2vw;
				padding: 0.4vw 2.4vw;
				justify-content: center;
				align-items: center;
				gap: 0.52vw;
				border-radius: 0.52vw;
				background: var(--Dark-gray, #3c3c3c);
				color: #fff;

				/* web. light.20 */
				font-family: Pretendard;
				font-size: 1.04vw;
				font-style: normal;
				font-weight: 300;
				line-height: 1.4; /* 140% */
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}

@media (max-width: 1400px) {
	.home {
		width: 100%;
		display: flex;
		flex-direction: column;
		white-space: pre-wrap;

		.intro-background {
			width: 100%;
			background-color: black;
		}
		&-intro {
			//intro section
			width: 900px;

			&-what {
				width: 771px;
				height: 250px;
				//margin-top: 200px;
				//background-color: blue;
				//display: flex;
				//flex-direction: column;
				&-head {
					@include font-setting(white, 600, 45px, 60px);
				}
				&-about {
					font-size: 18px;
					margin-top: 20px;
				}
				&-body {
					width: 542px;
					margin-top: 20px;
					@include font-setting(white, 600, 24px, 28px);
				}
			}
			&-goal {
				width: 771px;
				height: 300px;
				//margin-top: 200px;
				//background-color: blue;
				//display: flex;
				//flex-direction: column;
				//background-color: blue;
				&-head {
					@include font-setting(white, 600, 45px, 28px);
				}
				&-body1 {
					width: 550px;
					//margin-top: 50px;
					//@include font-setting(black, 600, 20px, 28px);
					margin-left: 60px;
				}
				&-body2 {
					width: 550px;
					margin-left: 0px;
					//margin-top: 15px;
					//@include font-setting(black, 600, 20px, 28px);
				}
			}
			&-contact {
				color: white;
				position: relative;
				width: 900px;
				height: 160px;
				margin-top: 150px;
				//margin-left: 18.9583%;
				&-head {
					@include font-setting(white, 600, 25px, 28px);
					margin-bottom: 16px;
				}
				&-email {
					background-color: black;
					width: 900px;
					height: 44px;
					input {
						width: 750px;
						height: 44px;
						border-radius: 10px;
						border: 1px solid var(--Light-gray, #f0f0f0);
						background-color: black;
						@include font-setting(#f0f0f0, 300, 20px, 28px);
						padding-left: 21px;
						margin-right: 20px;
					}
					.button {
						border-radius: 10px;
						width: 184px;
						height: 44px;
						width: 130px;
						background: var(--Light-gray, #f0f0f0);
						@include font-setting(#3c3c3c, 300, 20px, 28px);
					}
					margin-bottom: 16px;
				}
				&-body {
					@include font-setting(#9e9e9e, 400, 16px, 28px);
				}
			}
		}

		&-research {
			position: relative;
			width: 900px;
			height: 750px;
			background-color: white;
			margin-bottom: 150px;
			margin: auto;

			&-head {
				position: relative;
				margin-top: 142px;
				//margin-left: 18.9583%;
				width: 900px;
				height: 46px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				span {
					@include font-setting(black, 600, 45px, 28px);
				}
				button {
					width: 161px;
					height: 44px;
					border-radius: 10px;
					margin-left: 100px;
					border: 1px solid var(--Dark-gray, #3c3c3c);
					@include font-setting(#3c3c3c, 300, 20px, 28px);
				}
			}
		}
	}
}

@media (max-width: 1023px) {
	.home {
		width: 100%;
		display: flex;
		flex-direction: column;
		&-anime {
			//animation section
			video {
				margin-top: 55px;
			}
		}
		.intro-background {
			width: 100%;
			background-color: black;
		}
		&-intro {
			//intro section
			width: 700px;

			&-what {
				width: 600px;
				height: 250px;
				//margin-top: 200px;
				//background-color: blue;
				display: flex;
				flex-direction: column;
				&-head {
					align-self: start;
					margin-bottom: 0px;
					@include font-setting(white, 600, 30px, 45px);
				}
				&-about {
					margin-top: 30px;
				}
				&-body {
					width: 500px;
					margin-top: 20px;
					@include font-setting(white, 600, 18px, 28px);
				}
			}
			&-goal {
				width: 600px;
				height: 250px;
				//margin-top: 200px;
				//background-color: blue;
				display: flex;
				flex-direction: column;
				//background-color: blue;
				&-head {
					@include font-setting(white, 600, 30px, 28px);
				}
				&-body1 {
					width: 500px;
					margin-top: 50px;
					margin-left: 0px;
					@include font-setting(white, 600, 18px, 28px);
				}
				&-body2 {
					width: 500px;
					margin-top: 15px;
					@include font-setting(white, 600, 18px, 28px);
				}
			}
			&-contact {
				color: white;
				position: relative;
				width: 700px;
				height: 160px;
				margin-top: 150px;
				//margin-left: 18.9583%;
				&-head {
					@include font-setting(white, 600, 20px, 28px);
					margin-bottom: 16px;
				}
				&-email {
					background-color: black;
					width: 700px;
					height: 44px;
					input {
						width: 550px;
						height: 44px;
						border-radius: 10px;
						border: 1px solid var(--Light-gray, #f0f0f0);
						background-color: black;
						@include font-setting(#f0f0f0, 300, 20px, 28px);
						padding-left: 21px;
						margin-right: 20px;
					}
					.button {
						border-radius: 10px;
						width: 184px;
						height: 44px;
						width: 130px;
						background: var(--Light-gray, #f0f0f0);
						@include font-setting(#3c3c3c, 300, 20px, 28px);
					}
					margin-bottom: 16px;
				}
				&-body {
					@include font-setting(#9e9e9e, 400, 14px, 28px);
				}
			}
		}

		&-research {
			position: relative;
			width: 700px;
			height: 650px;
			background-color: white;
			margin-bottom: 150px;
			margin: auto;

			&-head {
				position: relative;
				margin-top: 142px;
				//margin-left: 18.9583%;
				width: 700px;
				height: 46px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				span {
					@include font-setting(black, 600, 45px, 28px);
				}
				button {
					width: 161px;
					height: 44px;
					border-radius: 10px;
					border: 1px solid var(--Dark-gray, #3c3c3c);
					@include font-setting(#3c3c3c, 300, 20px, 28px);
				}
			}
		}
	}
}
// common
@media (max-width: 767px) {
	.home {
		width: 100%;
		display: flex;
		flex-direction: column;

		.intro-background {
			width: 100%;
			background-color: black;
		}
		&-intro {
			//intro section
			width: 350px;

			&-what {
				width: 350px;
				height: 250px;
				//margin-top: 200px;
				//background-color: blue;
				display: flex;
				flex-direction: column;
				&-head {
					@include font-setting(white, 600, 19px, 28px);
				}
				&-about {
					margin-top: 30px;
					font-size: 17px;
					font-size: 12px;
				}
				&-body {
					width: 300px;
					margin-top: 10px;
					@include font-setting(white, 600, 13px, 1.7);
				}
			}
			&-goal {
				width: 350px;
				height: 350px;
				//margin-top: 200px;
				//background-color: blue;
				display: flex;
				flex-direction: column;
				//background-color: blue;
				&-head {
					width: 350px;
					@include font-setting(white, 600, 19px, 28px);
				}
				&-body1 {
					width: 350px;
					margin-top: 30px;
					height: fit-content;
					@include font-setting(white, 600, 12px, 1.7);
				}
				&-body2 {
					width: 350px;
					margin-top: 15px;
					height: fit-content;
					@include font-setting(white, 600, 12px, 1.7);
				}
			}
			&-contact {
				color: white;
				position: relative;
				width: 350px;
				height: 160px;
				margin-top: 150px;
				//margin-left: 18.9583%;
				&-head {
					@include font-setting(white, 600, 15px, 28px);
					margin-bottom: 16px;
				}
				&-email {
					background-color: black;
					width: 350px;
					height: 44px;
					input {
						width: 240px;
						height: 44px;
						border-radius: 10px;
						border: 1px solid var(--Light-gray, #f0f0f0);
						background-color: black;
						@include font-setting(#f0f0f0, 300, 12px, 28px);
						padding-left: 20px;
						margin-right: 20px;
					}
					.button {
						border-radius: 10px;
						width: 90px;
						height: 44px;
						background: var(--Light-gray, #f0f0f0);
						@include font-setting(#3c3c3c, 300, 14px, 28px);
					}
					margin-bottom: 16px;
				}
				&-body {
					@include font-setting(#9e9e9e, 400, 12px, 1.7);
				}
			}
		}

		&-research {
			position: relative;
			width: 350px;
			height: 480px;
			background-color: white;
			margin-bottom: 150px;
			margin: auto;

			&-head {
				position: relative;
				margin-top: 142px;
				//margin-left: 18.9583%;
				width: 350px;
				height: 46px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				span {
					@include font-setting(black, 600, 20px, 28px);
				}
				button {
					width: 70px;
					height: 24px;
					border-radius: 5px;

					border: 1px solid var(--Dark-gray, #3c3c3c);
					@include font-setting(#3c3c3c, 300, 11px, 25px);
				}
			}
		}
	}
}

.Section {
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 100px auto;
	width: 1192px;
}

.SectionTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 40px;
	color: #000;
	font-family: Pretendard;
	font-size: 60px;
	font-style: normal;
	font-weight: 600;
	line-height: 80px;
	opacity: 0.9;
	text-align: start;
}

.ViewButton {
	width: 161px;
	height: 44px;
	background-color: white;
	border-radius: 10px;
	border: 1px solid var(--Dark-gray, #3c3c3c);
	color: var(--Dark-gray, #3c3c3c);
	text-align: center;
	font-family: Pretendard;
	font-size: 20px;
	font-style: normal;
	font-weight: 300;
	line-height: 28px;

	&:hover {
		cursor: pointer;
		background: var(--Light-gray, #f0f0f0);
	}
}

// Project

.ProjectBox {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

// Event

.EventSection {
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 10px auto;
	width: 1192px;
	overflow: hidden;
}

.EventBox {
	overflow: hidden;
}

.EventBoxSlide {
	width: '100%';
	overflow-x: 'hidden';
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	transform: translate(0px);
	-ms-user-select: none;
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	user-select: none;

	&.InsightEmpty {
		> div {
			margin-right: 20px;
		}
	}

	&.InsightFull {
		justify-content: space-between;
	}
}

@media (max-width: 1400px) {
	.EventBox {
		width: 900px;
	}

	.EventBoxSlide {
		width: 900px;
	}
}

@media (max-width: 1023px) {
	.EventBox {
		width: 700px;
	}

	.EventBoxSlide {
		width: 700px;
	}
}

@media (max-width: 767px) {
	.EventBox {
		width: 350px;
	}

	.EventBoxSlide {
		width: 350px;
		&.InsightEmpty {
			> div {
				margin-right: 9px;
			}
		}
	}

	.ViewButton {
		width: 70px;
		height: 24px;
		border-radius: 5px;
		font-size: 11px;
		line-height: 25px;
	}
}
