.Events {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 77px;
  margin: auto;
}

.Section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 100px auto;
  width: 1192px;
}

.SectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  color: #000;
  font-family: Pretendard;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 80px;
  opacity: 0.9;
  text-align: start;
}

.SubPhrase {
  color: var(--Midium-gray, #9e9e9e);
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 50px;
}

.ButtonArrange {
  display: flex;
  justify-content: center;
  align-items: center;
}

.EventCardPaging {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.EventCardPaging img:first-child {
  margin-right: 40px;
}
.EventCardPaging img:first-child:hover {
  cursor: pointer;
}
.EventCardPaging img:last-child {
  margin-left: 40px;
}
.EventCardPaging img:last-child:hover {
  cursor: pointer;
}

circle {
  fill: #d9d9d9;
}

.EventBox__past {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
}

.EventCard {
  display: flex;
  flex-direction: column;
  width: 432px;
  height: 233px;
  flex-shrink: 0;
  background: var(--Dark-gray, #3c3c3c);
  padding: 14px 18px;
  margin-right: 24px;
  cursor: pointer;
}
.EventCard.InEvent {
  height: 388px;
}
.EventCard.Past {
  width: 584px;
  height: 372px;
  background: white;
  border: 1px solid var(--Dark-gray, #3c3c3c);
  padding: 0px;
  margin-bottom: 30px;
}
.EventCard.Coming {
  opacity: 0.5;
}

.EventCard-noevent {
  opacity: 0.2;
}

.EventCardHoverAction {
  display: flex;
  position: absolute;
  width: 582px;
  height: 278px;
  opacity: 0;
}
.EventCardHoverAction:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(60, 60, 60, 0.8);
  transition: 0.3s;
  opacity: 1;
}
.EventCardHoverAction:hover div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 192px;
  height: 44px;
  color: #fff;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 45px;
  border: 1px solid white;
  border-radius: 10px;
  align-items: center;
}

.EventCardImage {
  width: 582px;
  height: 278px;
  flex-shrink: 0;
  background: #717171;
  -o-object-fit: contain;
     object-fit: contain;
}

.EventCardPeriod {
  display: flex;
  justify-content: space-between;
}
.EventCardPeriod.Past {
  width: 100%;
  height: 94px;
  padding: 10px;
  flex-direction: column;
}

.EventCardMonth {
  color: #fff;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 6px;
  margin-left: 4px;
}

.EventCardDday {
  color: var(--Midium-gray, #9e9e9e);
  text-align: right;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
}

.EventCardDate {
  color: #fff;
  font-family: Pretendard;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 30px;
}
.EventCardDate.Past {
  color: var(--Midium-gray, #9e9e9e);
  font-size: 20px;
  font-weight: 300;
  margin: 0px;
}

.EventCardTitle {
  color: #fff;
  height: 50px;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  margin-left: 4px;
  margin-bottom: 30px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.EventCardTitle.InEvent {
  height: 200px;
  -webkit-line-clamp: 8;
}
.EventCardTitle.Past {
  display: block;
  color: #000;
  font-size: 30px;
  line-height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 40px;
  margin: 0px;
}
.EventCardTitle.Coming {
  margin: 34px 0px;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 54.5px;
}

.EventCardTime {
  color: #fff;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
}

.SubtitleFont {
  color: #000;
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.DescriptFont {
  position: relative;
  color: #000;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.EventInformation {
  display: flex;
  margin-top: 10px;
}

.EventImage {
  -o-object-fit: contain;
     object-fit: contain;
  margin-right: 20px;
  width: 432px;
  height: 405px;
  flex-shrink: 0;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(200, 200, 200);
}

.EventOverview {
  width: 736px;
}

.EventTitle {
  width: 736px;
  height: 180px;
  color: #000;
  font-family: Pretendard;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  margin-bottom: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.EventTitle:hover {
  display: block;
}

.EventPeriod {
  display: flex;
  margin-bottom: 30px;
}
.EventPeriod :first-child {
  margin-right: 20px;
}

.EventVenue,
.EventTime,
.EventWebsite {
  display: flex;
  margin-bottom: 20px;
}
.EventVenue :first-child,
.EventTime :first-child,
.EventWebsite :first-child {
  margin-right: 30px;
}
.EventVenue .SubtitleFont,
.EventTime .SubtitleFont,
.EventWebsite .SubtitleFont {
  white-space: nowrap;
}

.EventPurpose {
  width: 62%;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  margin-bottom: 70px;
}
.EventPurpose :first-child {
  margin-bottom: 20px;
}

.EventDetail {
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
}
.EventDetail :first-child {
  margin-bottom: 20px;
}

.EventCarousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 70px;
  height: 100%;
}

.EventCarouselImages {
  display: flex;
  margin: 0px 53px;
  width: -moz-fit-content;
  width: fit-content;
  overflow: hidden;
}

.EventCarouselSlide {
  width: inherit;
  display: flex;
  justify-content: center;
  transform: translate(0px);
}

.CarouselImage {
  height: 475px;
  width: 1066px;
  -o-object-fit: contain;
     object-fit: contain;
}

.ImageEmpty {
  width: inherit;
  font-family: Pretendard;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  text-align: center;
}

.EventMap {
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
}

.EventMapLocation {
  margin-top: 30px;
  width: 1192px;
  height: 475px;
  background: #d9d9d9;
}
.EventMapLocation .div {
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.EventGallery {
  display: flex;
  flex-direction: column;
}

.EventGalleryImages {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 475px;
}
.EventGalleryImages img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 200px;
}

@media (max-width: 1400px) {
  .Section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 80px auto;
    width: 900px;
  }
  .SectionTitle {
    position: relative;
    width: 900px;
    font-size: 45px;
  }
  .EventCardPaging {
    width: 900px;
  }
  .EventCard {
    width: 368px;
    height: 233px;
  }
  .EventCard.InEvent {
    height: 330px;
  }
  .EventCard.Past {
    width: 440px;
    height: 280px;
    margin-bottom: 30px;
    margin-right: 20px;
  }
  .EventCardHoverAction {
    width: 438px;
    height: 210px;
  }
  .EventCardHoverAction:hover div {
    width: 192px;
    height: 44px;
  }
  .EventCardImage {
    width: 438px;
    height: 210px;
  }
  .EventCardPeriod.Past {
    height: 70px;
    padding: 7px;
  }
  .EventCardDate.Past {
    font-size: 15px;
  }
  .EventCardTitle.Past {
    font-size: 22px;
    line-height: 28px;
  }
  .EventCardTitle.InEvent {
    height: 150px;
    -webkit-line-clamp: 6;
  }
  .EventInformation .SubtitleFont {
    font-size: 22px;
    line-height: 21px;
  }
  .EventInformation .DescriptFont {
    font-size: 16px;
    line-height: 21px;
  }
  .SubtitleFont {
    font-size: 27px;
    line-height: 24px;
  }
  .DescriptFont {
    font-size: 20px;
    line-height: 24px;
  }
  .EventImage {
    width: 324px;
    height: 303px;
  }
  .EventOverview {
    width: 556px;
  }
  .EventTitle {
    width: 556px;
    height: 135px;
    font-size: 41px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  .EventPeriod {
    display: flex;
    margin-bottom: 22px;
  }
  .EventPeriod :first-child {
    margin-right: 15px;
  }
  .EventVenue,
  .EventTime,
  .EventWebsite {
    margin-bottom: 15px;
  }
  .EventVenue :first-child,
  .EventTime :first-child,
  .EventWebsite :first-child {
    margin-right: 22px;
  }
  .CarouselImage {
    height: 365px;
    width: 775px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .EventMapLocation {
    width: 900px;
    height: 365px;
  }
  .EventGalleryImages {
    height: 365px;
  }
}
@media (max-width: 1023px) {
  .Section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 50px auto;
    width: 700px;
  }
  .SectionTitle {
    position: relative;
    width: 700px;
    font-size: 45px;
    margin-bottom: 15px;
  }
  .SubPhrase {
    font-size: 25px;
    line-height: 28px;
    margin-bottom: 30px;
  }
  .ButtonArrange {
    margin-top: 50px;
  }
  .EventCardPaging {
    width: 700px;
  }
  .EventCard {
    width: 286px;
    height: 190px;
    margin-right: 12px;
  }
  .EventCard.InEvent {
    height: 245px;
  }
  .EventCard.Past {
    width: 340px;
    height: 210px;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .EventCardHoverAction {
    width: 338px;
    height: 157px;
  }
  .EventCardHoverAction:hover div {
    width: 144px;
    height: 33px;
    font-size: 15px;
  }
  .EventCardImage {
    width: 100%;
    height: 157px;
  }
  .EventCardPeriod.Past {
    height: 53px;
    padding: 5px;
  }
  .EventCardMonth {
    font-size: 15.5px;
    line-height: 22px;
    margin-bottom: 4px;
    margin-left: 3px;
  }
  .EventCardDday {
    font-size: 15.5px;
    line-height: 22px;
  }
  .EventCardDate {
    font-size: 35px;
    line-height: 22px;
    margin-bottom: 23px;
  }
  .EventCardDate.Past {
    font-size: 12px;
  }
  .EventCardTitle {
    font-size: 17px;
    height: 39px;
    line-height: 19.5px;
    margin-left: 3px;
    margin-bottom: 30px;
  }
  .EventCardTitle.InEvent {
    height: 120px;
    -webkit-line-clamp: 5;
  }
  .EventCardTitle.Past {
    font-size: 16px;
    line-height: 20px;
  }
  .EventCardTitle.Coming {
    margin: 26.5px 0px;
    font-size: 31px;
    line-height: 42px;
  }
  .EventCardTime {
    font-size: 15.5px;
    line-height: 22px;
  }
  .SubtitleFont {
    font-size: 22px;
    line-height: 21px;
  }
  .DescriptFont {
    font-size: 16px;
    line-height: 21px;
  }
  .EventImage {
    width: 252px;
    height: 242px;
  }
  .EventOverview {
    width: 428px;
  }
  .EventTitle {
    width: 428px;
    height: 105px;
    font-size: 32px;
    line-height: 35px;
    margin-bottom: 18px;
  }
  .EventPeriod {
    display: flex;
    margin-bottom: 14px;
  }
  .EventPeriod :first-child {
    margin-right: 14px;
  }
  .EventVenue,
  .EventTime,
  .EventWebsite {
    margin-bottom: 9px;
  }
  .EventVenue :first-child,
  .EventTime :first-child,
  .EventWebsite :first-child {
    margin-right: 22px;
  }
  .CarouselImage {
    height: 284px;
    width: 573px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .EventMapLocation {
    width: 700px;
    height: 284px;
  }
  .EventGalleryImages {
    height: 284px;
  }
}
@media (max-width: 767px) {
  .Section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 20px auto;
    width: 350px;
  }
  .SectionTitle {
    position: relative;
    width: 350px;
    font-size: 25px;
    line-height: 30px;
  }
  .SubPhrase {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
  }
  .ButtonArrange {
    margin-top: 20px;
  }
  .EventCardPaging {
    width: 350px;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .EventCardPaging img {
    width: 10px;
    height: 5px;
  }
  .EventCardPaging img:first-child {
    margin-right: 20px;
    width: 5px;
    height: 9px;
  }
  .EventCardPaging img:last-child {
    margin-left: 20px;
    width: 5px;
    height: 9px;
  }
  .EventBox__past {
    display: block;
  }
  .EventCard {
    width: 163px;
    height: 103px;
  }
  .EventCard.InEvent {
    height: 155px;
  }
  .EventCard.Past {
    width: 350px;
    height: 225px;
  }
  .EventCard.Past img {
    width: 348px;
  }
  .EventCardHoverAction {
    width: 348px;
  }
  .EventCardPeriod.Past {
    height: 120px;
  }
  .EventCardMonth {
    font-size: 9px;
    line-height: 12.5px;
    margin-bottom: 2px;
    margin-left: 1.5px;
  }
  .EventCardDday {
    font-size: 9px;
    line-height: 12.5px;
  }
  .EventCardDate {
    font-size: 20px;
    line-height: 12.5px;
    margin-bottom: 5px;
  }
  .EventCardTitle {
    height: 22px;
    font-size: 9.5px;
    line-height: 11px;
    margin-left: 2px;
    margin-bottom: 10px;
  }
  .EventCardTitle.InEvent {
    height: 66px;
    -webkit-line-clamp: 6;
    margin-bottom: 15px;
  }
  .EventCardTitle.Coming {
    margin: 15px 0px;
    font-size: 15px;
    line-height: 24px;
  }
  .EventCardTime {
    font-size: 9px;
    line-height: 12.5px;
  }
  .EventInformation .SubtitleFont {
    font-size: 11px;
    line-height: 12px;
  }
  .EventInformation .DescriptFont {
    font-size: 8px;
    line-height: 10px;
  }
  .SubtitleFont {
    font-size: 11px;
    line-height: 12px;
  }
  .DescriptFont {
    font-size: 8px;
    line-height: 10px;
  }
  .EventImage {
    width: 126px;
    height: 117px;
  }
  .EventOverview {
    width: 214px;
  }
  .EventTitle {
    width: 214px;
    height: 45px;
    font-size: 16px;
    line-height: 15px;
    margin-bottom: 9px;
  }
  .EventPeriod {
    display: flex;
    margin-bottom: 7px;
  }
  .EventPeriod :first-child {
    margin-right: 7px;
  }
  .EventVenue,
  .EventTime,
  .EventWebsite {
    margin-bottom: 4px;
  }
  .EventVenue :first-child,
  .EventTime :first-child,
  .EventWebsite :first-child {
    margin-right: 11px;
  }
  .EventPurpose {
    margin: 45px 0px;
  }
  .EventPurpose :first-child {
    margin-bottom: 10px;
  }
  .EventDetail {
    margin-bottom: 45px;
  }
  .EventDetail :first-child {
    margin-bottom: 10px;
  }
  .EventCarousel {
    margin-bottom: 45px;
  }
  .CarouselImage {
    height: 142px;
    width: 203px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .EventMap {
    margin-bottom: 45px;
  }
  .EventMapLocation {
    width: 330px;
    height: 142px;
  }
  .EventGalleryImages {
    height: 142px;
  }
}/*# sourceMappingURL=events.css.map */