.ErrorHandlingPage {
  width: 100%;
  height: 600px;
  text-align: center;
  align-items: center;
  font-family: Pretendard;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 300px;
}

.Projects {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 77px;
  margin: auto;
}

.ProjectSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 100px auto;
  width: 1192px;
}

.ProjectExplore {
  display: grid;
}

.ProjectExploreUpperBar {
  display: flex;
  justify-content: space-between;
}

.StudyTypeBar {
  display: flex;
  flex-direction: row;
}
.StudyTypeBar button {
  border-bottom: 1px solid;
  padding: 4px 30px;
  background: #fff;
  color: var(--Midium-gray, #9e9e9e);
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.StudyTypeBar button:hover {
  background: var(--Light-gray, #f0f0f0);
  color: #000;
}
.StudyTypeBar button.true {
  background: var(--Dark-gray, #3c3c3c);
  color: #fff;
}

.SearchBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 280px;
  height: 36px;
  flex-shrink: 0;
  background: var(--Light-gray, #f0f0f0);
  padding-left: 16px;
  padding-right: 8px;
}
.SearchBar input {
  background: var(--Light-gray, #f0f0f0);
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  width: 220px;
}
.SearchBar input:focus::-moz-placeholder {
  color: transparent;
}
.SearchBar input:focus::placeholder {
  color: transparent;
}
.SearchBar img {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.ProjectList {
  grid-column-start: 1;
  grid-row-start: 2;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.ResultEmpty {
  grid-column-start: 1;
  grid-row-start: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1040px;
  color: #000;
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
  text-align: center;
}

.ResultSentence {
  color: #000;
  font-family: Pretendard;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.ProjectSelectionSector {
  grid-column-start: 2;
  grid-row-start: 2;
}

.ProjectSelectionBar {
  margin: 20px 0px 4px 25px;
  width: 280px;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;
  border: 1px solid var(--Dark-gray, #3c3c3c);
}
.ProjectSelectionBar .title {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  padding: 12px 17px 12px 18px;
  align-items: center;
  background: var(--Dark-gray, #3c3c3c);
  color: #fff;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.ProjectSelectionBar .title img {
  width: 18px;
  height: 10px;
}
.ProjectSelectionBar .sub {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 0px 10px;
  padding: 20px 4px 20px 19px;
  border-bottom: 1px solid var(--Dark-gray, #3c3c3c);
}
.ProjectSelectionBar .sub:last-child {
  border-bottom: 0px;
}
.ProjectSelectionBar .imageRotate {
  transform: rotate(180deg);
}

.checkbox {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border: 1px solid var(--Dark-gray, #3c3c3c);
  background: #fff;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  user-select: none;
}
.checkbox:hover {
  cursor: pointer;
}

.checkboxChecked {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border: 1px solid var(--Dark-gray, #3c3c3c);
  background: var(--Dark-gray, #3c3c3c);
  padding: 7px 3px 7px 3.5px;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  user-select: none;
}
.checkboxChecked:hover {
  cursor: pointer;
}

.ButtonArrange {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.Article {
  padding: 40px 0px;
  border-bottom: solid 1px var(--Midium-gray, #9e9e9e);
}
.Article .SubjectName {
  color: #000;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.Article .Content {
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
}

.Article:last-child {
  border-bottom: 0px;
}

.ProjectDetailsSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 60px auto;
  width: 1192px;
}

.BackImage {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 88px;
  height: 28px;
  flex-shrink: 0;
  margin-left: 10px;
  margin-bottom: 20px;
  transition: 0.3s;
}
.BackImage div {
  width: 49px;
  color: #000;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.BackImage .arrow {
  width: 28px;
  height: 28px;
  background-color: #fff;
}
.BackImage:hover {
  cursor: pointer;
  width: 98px;
  margin-left: 0px;
}

.ProjectStatus {
  margin-top: 27px;
  margin-bottom: 10px;
}

.ProjectTitle {
  color: #000;
  font-family: Pretendard;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
  width: 888px;
}

.ProjectGird {
  display: grid;
  grid-template-columns: 888px 2fr;
}

.ArticleTitle {
  color: #000;
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.StudyOverview {
  margin-top: 48px;
  margin-bottom: 20px;
}

.StudyOverviewContents {
  grid-column-start: 1;
  grid-row-start: 1;
}
.StudyOverviewContents .Contents {
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
}

.ProjectOfficial {
  margin-top: 60px;
}
.ProjectOfficial .Contents {
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  margin-top: 10px;
}

.ProjectCondition {
  margin-top: 40px;
}
.ProjectCondition button {
  margin-top: 10px;
  display: inline-flex;
  padding: 4px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: var(--Light-gray, #f0f0f0);
}
.ProjectCondition button label {
  color: #000;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.ShowMoreButton {
  color: #44c194;
  background-color: #fff;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
}
.ShowMoreButton:hover {
  cursor: pointer;
}

.StudyInformation {
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: span 2;
  width: 432px;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;
  border: 1px solid var(--Dark-gray, #3c3c3c);
  padding-top: 5px;
  padding-bottom: 11px;
  margin-top: 136px;
  margin-left: 24px;
}
.StudyInformation .Information {
  margin: 30px 0px 30px 44px;
}

.Contact {
  grid-column-start: 1;
  grid-row-start: 2;
}
.Contact .Information {
  margin-top: 20px;
}
.Contact .Information div {
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
}

.ParticipationCreteria {
  grid-column-start: 1;
  grid-row-start: 3;
}
.ParticipationCreteria .ArticleTitle {
  margin-bottom: 20px;
}

.CreteriaInformation {
  grid-column-start: 2;
  grid-row-start: 3;
  grid-row-end: span 4;
  width: 432px;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;
  border: 1px solid var(--Dark-gray, #3c3c3c);
  padding-top: 5px;
  padding-bottom: 11px;
  margin-top: 88px;
  margin-left: 24px;
}
.CreteriaInformation .Information {
  margin: 30px 0px 30px 44px;
}

.StudyPlan {
  grid-column-start: 1;
  grid-row-start: 4;
}
.StudyPlan .ArticleTitle {
  margin-bottom: 20px;
}
.StudyPlan .SubjectName {
  margin-bottom: 10px;
}
.StudyPlan .Content {
  margin-bottom: 30px;
}

.MeasuresTable {
  width: 100%;
  border-collapse: collapse;
  padding: 20px 0px;
  color: #000;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  overflow-x: auto;
}
.MeasuresTable th {
  width: 10%;
  height: 97px;
  flex-shrink: 0;
  border: 1px solid #000;
}
.MeasuresTable td {
  vertical-align: super;
  width: 30%;
  border: 1px solid #000;
}
.MeasuresTable .element {
  min-height: 161px;
  flex-shrink: 0;
  resize: none;
  padding: 9px 10px;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.CandI {
  grid-column-start: 1;
  grid-row-start: 5;
  padding-bottom: 20px;
}
.CandI .SubjectName {
  padding-top: 20px;
}

.CandIInfo {
  display: flex;
  align-items: center;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin: 20px 0px;
}
.CandIInfo .Link {
  display: inline-flex;
  padding: 4px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-right: 24px;
  background: var(--Light-gray, #f0f0f0);
}
.CandIInfo .Link:hover {
  cursor: pointer;
}
.CandIInfo .IsLink:hover {
  background: var(--Light-gray-Hovered, #e1e1e1);
}
.CandIInfo .SeparateLine {
  margin: 0px 30px;
  flex-shrink: 0;
  stroke-width: 1px;
  stroke: #000;
}

.Publications {
  grid-column-start: 1;
  grid-row-start: 6;
}

.MenuBar {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
  background: var(--Light-gray, #f0f0f0);
  color: #000;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-top: 10px;
}

.MenuBarContent {
  padding: 20px 0px;
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
}

.ProjectCardContent {
  padding: 24px 24px;
  border: 0.75px solid var(--Midium-gray, #9e9e9e);
  margin-bottom: 20px;
  width: 1192px;
  height: 250px;
  flex-shrink: 0;
}
.ProjectCardContent.inProject {
  width: 1040px;
}

.RowBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProjectCardProgress {
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.ProjectCardProgress.Completed {
  color: var(--point, #1acfaf);
}
.ProjectCardProgress.Terminated {
  color: #000;
}
.ProjectCardProgress.Active {
  color: #0094ff;
}

.ProjectCardID {
  color: var(--Midium-gray, #9e9e9e);
  text-align: right;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
}

.ProjectCardTitle {
  width: 65%;
  height: 64px;
  color: #000;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-top: 10px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ProjectDate {
  color: var(--Midium-gray, #9e9e9e);
  text-align: right;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 10px;
  padding-bottom: 36px;
}

.ProjectCardCategory {
  display: inline-flex;
  padding: 4px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: var(--Light-gray, #f0f0f0);
  color: #000;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 14px;
}

.ProjectCardLocation {
  color: #000;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px 0px;
  margin-top: 28px;
}

.ReadMoreButton {
  width: 192px;
  height: 44px;
  display: flex;
  padding: 8px 46px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--Dark-gray, #3c3c3c);
  color: var(--Dark-gray, #3c3c3c);
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  background-color: white;
}
.ReadMoreButton:hover {
  cursor: pointer;
  background: var(--Light-gray, #f0f0f0);
}

.PublicationCard {
  border: 1px solid var(--Dark-gray, #3c3c3c);
  width: 1040px;
  height: 134px;
  flex-shrink: 0;
  padding: 16px 18px;
  margin: 20px 0px;
}
.PublicationCard:hover {
  background-color: var(--Light-gray, #f0f0f0);
  cursor: pointer;
}

.PublicationCardTitle {
  width: 100%;
  height: 64px;
  color: #000;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 6px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.PublicationDate {
  color: var(--Midium-gray, #9e9e9e);
  text-align: left;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 4px;
  margin-top: 0px;
  padding-bottom: 0px;
}

@media (max-width: 1400px) {
  .ProjectSection {
    width: 900px;
    margin-top: 80px;
  }
  .ProjectCardContent {
    width: 900px;
    margin-bottom: 13px;
  }
  .ProjectCardContent.inProject {
    width: 780px;
  }
  .ProjectCardContent.inProject .ProjectCardTitle {
    width: 75%;
  }
  .ProjectExploreUpperBar {
    width: 780px;
  }
  .ProjectExploreUpperBar button {
    font-size: 16px;
    padding: 3px 22px;
  }
  .ProjectSelectionBar {
    margin: 13px 0px 2.5px 16px;
    width: 210px;
  }
  .ProjectSelectionBar .title {
    font-size: 16px;
    padding: 9px 15px 9px 13px;
    line-height: 18px;
  }
  .ProjectSelectionBar .title img {
    width: 16px;
    height: 9px;
  }
  .ProjectSelectionBar .sub {
    font-size: 16px;
    padding: 15px 3px 15px 15px;
    line-height: 18px;
    align-items: center;
    margin: 0px 9px;
  }
  .ProjectSelectionBar .imageRotate {
    transform: rotate(180deg);
  }
  .ProjectList {
    margin-top: 13px;
  }
  .ResultEmpty {
    width: 780px;
    font-size: 20px;
    line-height: 22px;
  }
  .ResultSentence {
    font-size: 30px;
    line-height: 40px;
    margin-top: 15px;
    margin-bottom: 22px;
  }
  .checkbox {
    width: 20px;
    height: 20px;
  }
  .checkboxChecked {
    width: 20px;
    height: 20px;
    padding: 4.5px 1.2px 4.5px 2.2px;
  }
  .ProjectDetailsSection {
    width: 900px;
  }
  .BackImage {
    margin-bottom: 10px;
  }
  .ProjectStatus {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .ProjectTitle {
    width: 640px;
  }
  .ProjectGird {
    grid-template-columns: 640px 2fr;
  }
  .StudyInformation,
  .CreteriaInformation {
    width: 310px;
  }
  .StudyInformation .Information,
  .CreteriaInformation .Information {
    margin: 21px 0px 21px 32px;
  }
  .PublicationCard {
    width: 785px;
    height: 134px;
    margin-bottom: 13px;
  }
}
@media (max-width: 1023px) {
  .ProjectSection {
    width: 700px;
    margin-top: 50px;
  }
  .ProjectExploreUpperBar {
    width: 550px;
  }
  .ProjectExploreUpperBar button {
    font-size: 12px;
    padding: 2px 14px;
  }
  .ProjectSelectionBar {
    margin: 8px 0px 1px 10px;
    width: 135px;
  }
  .ProjectSelectionBar .title {
    font-size: 10px;
    padding: 6px 10px 6px 8px;
    line-height: 14px;
  }
  .ProjectSelectionBar .title img {
    width: 12px;
    height: 7px;
  }
  .ProjectSelectionBar .sub {
    font-size: 10px;
    padding: 10px 2px 10px 10px;
    line-height: 12px;
    margin: 0px 8px;
  }
  .ProjectList {
    margin-top: 8px;
  }
  .SearchBar {
    width: 140px;
    height: 30px;
    padding-left: 8px;
    padding-right: 4px;
  }
  .SearchBar input {
    font-size: 14px;
    line-height: 20px;
    width: 108px;
  }
  .SearchBar img {
    width: 12px;
    height: 12px;
    flex-shrink: 0;
  }
  .ResultEmpty {
    width: 500px;
    font-size: 13px;
    line-height: 14px;
  }
  .ResultSentence {
    font-size: 21px;
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .checkbox {
    width: 13px;
    height: 13px;
  }
  .checkboxChecked {
    width: 13px;
    height: 13px;
    padding: 3px 0.8px 3px 1.4px;
  }
  .ProjectCardContent {
    width: 700px;
    height: 185px;
    margin-bottom: 8px;
    padding: 16px 16px;
  }
  .ProjectCardContent.inProject {
    width: 550px;
  }
  .ProjectCardContent.inProject .ProjectCardTitle {
    width: 85%;
  }
  .ProjectCardProgress {
    font-size: 16.5px;
  }
  .ProjectCardID {
    font-size: 15px;
  }
  .ProjectCardCategory {
    padding: 3px 22.5px;
    margin-top: 10.5px;
    font-size: 16.5px;
    line-height: 21px;
  }
  .ProjectCardTitle {
    height: 48px;
    font-size: 16.5px;
    line-height: 24px;
    margin-top: 7.5px;
  }
  .ProjectDate {
    font-size: 15px;
    margin-top: 7.5px;
    padding-bottom: 26px;
    line-height: 20px;
  }
  .ProjectCardLocation {
    font-size: 13.5px;
    margin: 0px;
  }
  .ReadMoreButton {
    width: 144px;
    height: 33px;
    font-size: 15px;
    padding: 0px;
    margin-bottom: 5px;
  }
  .ProjectDetailsSection {
    width: 700px;
  }
  .BackImage {
    width: 80px;
    height: 25px;
  }
  .BackImage div {
    width: -moz-fit-content;
    width: fit-content;
    font-size: 20px;
    line-height: 25px;
  }
  .BackImage .arrow {
    width: 25px;
    height: 25px;
  }
  .BackImage:hover {
    width: 90px;
  }
  .ProjectStatus {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .ProjectStatus p {
    font-size: 20px;
  }
  .ProjectTitle {
    font-size: 35px;
    width: 498px;
  }
  .ProjectGird {
    grid-template-columns: 498px 1fr;
  }
  .ProjectGird .Contents {
    font-size: 18px;
  }
  .StudyInformation,
  .CreteriaInformation {
    width: 195px;
  }
  .StudyInformation .Information,
  .CreteriaInformation .Information {
    margin: 10px 0px 10px 18px;
  }
  .StudyInformation .SubjectName,
  .CreteriaInformation .SubjectName {
    font-size: 14px;
    line-height: 18px;
  }
  .StudyInformation .Content,
  .CreteriaInformation .Content {
    font-size: 12px;
    line-height: 18px;
  }
  .ShowMoreButton {
    font-size: 18px;
  }
  .MenuBar {
    font-size: 20px;
  }
  .MenuBarContent {
    font-size: 18px;
  }
  .MeasuresTable {
    padding: 20px 0px;
    font-size: 20px;
    line-height: 24px;
  }
  .MeasuresTable .element {
    font-size: 20px;
    line-height: 24px;
  }
  .CandIInfo {
    font-size: 20px;
  }
  .PublicationCard {
    width: 611px;
    height: 110px;
    margin-bottom: 8px;
    padding: 16px 16px;
  }
  .PublicationCardTitle {
    height: 48px;
    font-size: 16.5px;
    line-height: 24px;
  }
  .PublicationDate {
    font-size: 15px;
    margin-top: 7.5px;
    padding-bottom: 26px;
    line-height: 20px;
  }
}
@media (max-width: 767px) {
  .ProjectSection {
    width: 350px;
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .ProjectExploreUpperBar {
    width: 263px;
  }
  .ProjectExploreUpperBar button {
    font-size: 4px;
    padding: 1px 2px;
    line-height: 7px;
  }
  .SearchBar {
    width: 80px;
    height: 20.5px;
    padding-left: 4.5px;
    padding-right: 2px;
  }
  .SearchBar input {
    font-size: 8px;
    line-height: 11.5px;
    width: 62px;
  }
  .SearchBar img {
    width: 7px;
    height: 7px;
    flex-shrink: 0;
  }
  .ResultEmpty {
    width: 263px;
    font-size: 8px;
    line-height: 10px;
  }
  .ResultSentence {
    font-size: 10px;
    line-height: 14px;
    margin-top: 5px;
    margin-bottom: 8px;
  }
  .ProjectCardContent {
    width: 350px;
    height: 100px;
    padding: 9px 9px;
  }
  .ProjectCardContent.inProject {
    width: 263px;
  }
  .ProjectDate {
    font-size: 10px;
    line-height: 10px;
    margin-top: 4px;
    padding-bottom: 17px;
  }
  .ProjectCardProgress {
    font-size: 9.5px;
    line-height: 12px;
  }
  .ProjectCardID {
    font-size: 8.5px;
    line-height: 12px;
  }
  .ProjectCardCategory {
    padding: 1px 7px;
    margin-top: 3.5px;
    font-size: 5.5px;
    line-height: 12px;
  }
  .ProjectCardTitle {
    height: 27px;
    font-size: 9.5px;
    line-height: 13.5px;
    margin-top: 4px;
  }
  .ProjectCardLocation {
    font-size: 7.5px;
    margin: 0px;
  }
  .ReadMoreButton {
    width: 72px;
    height: 20px;
    font-size: 7px;
    padding: 0px;
    margin-bottom: 3px;
    border-radius: 5px;
  }
  .ProjectSelectionBar {
    margin: 8px 0px 1px 0px;
    width: 80px;
  }
  .ProjectSelectionBar .title {
    font-size: 5.5px;
    padding: 4px 5.5px 4px 4.5px;
    line-height: 15.5px;
  }
  .ProjectSelectionBar .title img {
    width: 6.5px;
    height: 4px;
  }
  .ProjectSelectionBar .sub {
    font-size: 5.5px;
    padding: 5.5px 1px 5.5px 5.5px;
    line-height: 9px;
    margin: 0px 4px;
  }
  .ProjectSelectionBar .checkbox {
    width: 10px;
    height: 10px;
  }
  .ProjectSelectionBar .checkboxChecked {
    width: 10px;
    height: 10px;
    padding: 1.5px 0.4px 1.5px 0.7px;
  }
  .ButtonArrange {
    margin-top: 30px;
  }
  .Article .SubjectName {
    font-size: 20px;
    line-height: 28px;
  }
  .Article .Content {
    font-size: 15px;
    line-height: 20px;
  }
  .Article:last-child {
    border-bottom: 0px;
  }
  .ProjectDetailsSection {
    margin: 0px auto;
    width: 330px;
  }
  .BackImage {
    width: 60px;
    height: 18px;
    margin-left: 5px;
  }
  .BackImage div {
    font-size: 15px;
    line-height: 18px;
  }
  .BackImage .arrow {
    width: 18px;
    height: 18px;
  }
  .BackImage:hover {
    width: 65px;
  }
  .ProjectStatus {
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .ProjectStatus p {
    font-size: 15px;
  }
  .ProjectTitle {
    font-size: 25px;
    line-height: 34px;
    width: 330px;
  }
  .ProjectGird {
    display: flex;
    flex-direction: column;
    grid-template-columns: 400px;
  }
  .ArticleTitle {
    font-size: 22px;
    line-height: 28px;
  }
  .StudyOverview {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .StudyOverviewContents .Contents {
    font-size: 15px;
    line-height: 20px;
  }
  .ProjectOfficial {
    margin-top: 30px;
  }
  .ProjectOfficial .Contents {
    font-size: 15px;
    line-height: 20px;
    margin-top: 10px;
  }
  .ProjectCondition {
    margin-top: 20px;
  }
  .ProjectCondition button label {
    font-size: 15px;
    line-height: 22px;
  }
  .ShowMoreButton {
    font-size: 15px;
    line-height: 20px;
  }
  .StudyInformation,
  .CreteriaInformation {
    width: 100%;
    flex-shrink: 0;
    margin: 40px 0px 0px 0px;
  }
  .StudyInformation .Information,
  .CreteriaInformation .Information {
    margin: 10px 0px 10px 18px;
  }
  .StudyInformation .SubjectName,
  .CreteriaInformation .SubjectName {
    font-size: 14px;
    line-height: 18px;
  }
  .StudyInformation .Content,
  .CreteriaInformation .Content {
    font-size: 12px;
    line-height: 18px;
  }
  .Contact .Information {
    margin-top: 20px;
  }
  .Contact .Information div {
    font-size: 15px;
    line-height: 20px;
  }
  .ParticipationCreteria .ArticleTitle {
    margin-bottom: 0px;
  }
  .MenuBar {
    padding: 10px 20px;
    font-size: 18px;
    line-height: 20px;
  }
  .MenuBarContent {
    padding: 10px 0px;
    font-size: 15px;
    line-height: 28px;
  }
  .MeasuresTable {
    padding: 20px 0px;
    font-size: 15px;
    line-height: 20px;
  }
  .MeasuresTable .element {
    font-size: 15px;
    line-height: 20px;
  }
  .StudyPlan .ArticleTitle {
    margin-bottom: 10px;
  }
  .CandI {
    padding-bottom: 20px;
  }
  .CandI .SubjectName {
    padding-top: 20px;
  }
  .CandIInfo {
    font-size: 15px;
    line-height: 20px;
    margin: 10px 0px;
  }
  .CandIInfo .Link {
    display: inline-flex;
    padding: 4px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-right: 24px;
    background: var(--Light-gray, #f0f0f0);
  }
  .CandIInfo .SeparateLine {
    margin: 0px 30px;
    flex-shrink: 0;
    stroke-width: 1px;
    stroke: #000;
  }
  .PublicationCard {
    width: 330px;
    height: 65px;
    padding: 9px 9px;
  }
  .PublicationCard .ProjectCardTitle {
    height: 28px;
  }
  .PublicationCardTitle {
    height: 27px;
    font-size: 9.5px;
    line-height: 13.5px;
  }
  .PublicationDate {
    font-size: 10px;
    line-height: 10px;
    margin-top: 4px;
    padding-bottom: 17px;
  }
}/*# sourceMappingURL=projects.css.map */