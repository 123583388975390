.iDetail {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	& * {
		word-break: keep-all;
	}
	&-back {
		width: 1192px;
		margin: auto;
		display: flex;
		flex-direction: row;
		margin-top: 132px;
		align-items: center;
		&-box {
			position: relative;
			display: flex;
			flex-direction: row;
			width: fit-content;
			justify-content: end;
			align-items: center;
			div {
				color: #000;
				margin-top: 2px;
				/* web. re.22 */
				font-family: Pretendard;
				font-size: 22px;
				font-style: normal;
				font-weight: 400;
				line-height: 28px; /* 127.273% */
				margin-left: 40px;
			}
			img {
				position: absolute;
				left: 0px;
			}
		}
		&-box:hover {
			cursor: pointer;
			img {
				left: -10px;
				transition: 0.2s ease-in-out;
			}
		}
	}

	&-title {
		width: 1192px;
		margin: auto;
		color: #000;
		margin-top: 30px;
		/* Pre.Semi 60 */
		font-family: Pretendard;
		font-size: 60px;
		font-style: normal;
		font-weight: 600;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		line-height: 70px; /* 46.667% */
	}
	&-typedate {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 1192px;
		margin: auto;
		margin-top: 30px;
		&-type {
			color: var(--Midium-gray, #9e9e9e);

			/* web. semi.30 */
			font-family: Pretendard;
			font-size: 30px;
			font-style: normal;
			font-weight: 600;
			line-height: 28px; /* 93.333% */
		}
		&-date {
			@extend .iDetail-typedate-type;
		}
	}
	&-pdf {
		width: 1192px;
		height: fit-content;
		margin: auto;
		margin-top: 100px;
		//border: 2px solid black;
		.pageContainer {
			margin-bottom: 50px; /* Add margin between pages */
		}
	}
	&-back-container {
		width: 1196px;
		height: 200px;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		.readmore {
			width: 186px;
			height: 44px;
			margin: auto;

			border-radius: 10px;
			border: 1px solid var(--Dark-gray, #3c3c3c);
			background-color: white;
			color: var(--Dark-gray, #3c3c3c);
			text-align: center;
			/* web. light.20 */
			font-family: Pretendard;
			font-size: 20px;
			font-style: normal;
			font-weight: 300;
			line-height: 40px; /* 140% */
		}
		.readmore:hover {
			cursor: pointer;
			background: var(--Light-gray, #f0f0f0);
		}
	}
}

@media (max-width: 1400px) {
	.iDetail {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		&-back {
			width: 900px;

			div {
				color: #000;
			}
		}
		.researcherdetail-back:hover {
			cursor: pointer;
		}
		&-title {
			width: 900px;
			margin: auto;
			color: #000;
			margin-top: 30px;
			/* Pre.Semi 60 */
			font-family: Pretendard;
			font-size: 40px;
			font-style: normal;
			font-weight: 600;
			line-height: 50px; /* 46.667% */
		}
		&-typedate {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 900px;
			margin: auto;
			margin-top: 25px;
			&-type {
				color: var(--Midium-gray, #9e9e9e);

				/* web. semi.30 */
				font-family: Pretendard;
				font-size: 25px;
				font-style: normal;
				font-weight: 600;
				line-height: 28px; /* 93.333% */
			}
		}
		&-pdf {
			width: 900px;
			height: fit-content;
			margin: auto;
			margin-top: 100px;
			//border: 2px solid black;
			.pageContainer {
				margin-bottom: 50px; /* Add margin between pages */
			}
		}
		&-back-container {
			width: 900px;
		}
	}
}

@media (max-width: 1023px) {
	.iDetail {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		&-back {
			width: 700px;
		}
		.researcherdetail-back:hover {
			cursor: pointer;
		}
		&-title {
			width: 700px;
			margin: auto;
			color: #000;
			margin-top: 30px;
			/* Pre.Semi 60 */
			font-family: Pretendard;
			font-size: 30px;
			font-style: normal;
			font-weight: 600;
			line-height: 40px; /* 46.667% */
		}
		&-typedate {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 700px;
			margin: auto;
			margin-top: 25px;
			&-type {
				color: var(--Midium-gray, #9e9e9e);

				/* web. semi.30 */
				font-family: Pretendard;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 24px; /* 93.333% */
			}
		}
		&-pdf {
			width: 700px;
			height: fit-content;
			margin: auto;
			margin-top: 60px;
			//border: 2px solid black;
			.pageContainer {
				margin-bottom: 50px; /* Add margin between pages */
			}
		}
		&-back-container {
			width: 700px;
		}
	}
}

@media (max-width: 767px) {
	.iDetail {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		&-back {
			width: 350px;

			&-box {
				img {
					width: 20px;
				}
				div {
					font-size: 15px;
					margin-left: 30px;
					// font-style: normal;
					// font-weight: 400;
					// line-height: 28px; /* 127.273% */
					// margin-left: 11px;
				}
			}
		}
		.researcherdetail-back:hover {
			cursor: pointer;
		}
		&-title {
			width: 350px;
			margin: auto;
			color: #000;
			margin-top: 30px;
			/* Pre.Semi 60 */
			font-family: Pretendard;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 25px; /* 46.667% */
		}
		&-typedate {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 350px;
			margin: auto;
			margin-top: 25px;
			&-type {
				color: var(--Midium-gray, #9e9e9e);

				/* web. semi.30 */
				font-family: Pretendard;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px; /* 93.333% */
			}
		}
		&-pdf {
			width: 350px;
			height: fit-content;
			margin: auto;
			margin-top: 40px;
			//border: 2px solid black;
			.pageContainer {
				margin-bottom: 50px; /* Add margin between pages */
			}
		}
		&-back-container {
			width: 350px;
			.readmore {
				width: 100px;
				height: 30px;
				border-radius: 5px;
				font-size: 15px;
				line-height: 30px;
			}
		}
	}
}
