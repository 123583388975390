@charset "UTF-8";
.gnb, .gnb-white {
  display: flex;
  width: 100%;
  height: 77px;
  background: rgba(60, 60, 60, 0.8);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  flex-direction: row;
  position: fixed;
  z-index: 100;
}
.gnb-newlogo {
  position: absolute;
  left: 39px;
  width: 10.6%;
}
.gnb-newlogo:hover {
  cursor: pointer;
}
.gnb-logo {
  position: absolute;
  width: 6.4%;
  left: 14.3%;
}
.gnb-logo:hover {
  cursor: pointer;
}
.gnb-bar {
  display: none;
  position: absolute;
  left: 21px;
}
.gnb-bar:hover {
  cursor: pointer;
}
.gnb-menu {
  width: 554px;
  height: 31px;
  position: absolute;
  left: 28.8%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: white;
  font-size: 20px;
  font-family: Pretendard;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 32px;
  letter-spacing: normal;
}
.gnb-menu div:hover {
  text-decoration: underline;
  cursor: pointer;
  transition: 0.25s;
}
.gnb .gnb-tiny, .gnb-white .gnb-tiny {
  position: absolute;
  width: 2.7%;
  left: 21%;
  top: 17px;
  color: var(--Dark-gray, white);
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px; /* 175% */
}
.gnb .gnb-tiny:hover, .gnb-white .gnb-tiny:hover {
  cursor: pointer;
}
.gnb .side, .gnb-white .side {
  position: fixed;
  display: none;
  margin-top: 158vh;
  width: 208px;
  height: 150vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 200;
}
.gnb .side .side-menu, .gnb-white .side .side-menu {
  width: 208px;
  height: 150vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
.gnb .side .side-menu .side-menu-button, .gnb-white .side .side-menu .side-menu-button {
  display: flex;
  align-items: center;
  height: 60px;
  color: #fff;
  /* web. light.20 */
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  width: 208px !important;
  z-index: 1000 !important;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.6);
}
.gnb .side .side-menu .side-menu-button div, .gnb-white .side .side-menu .side-menu-button div {
  margin-left: 18px;
}
.gnb .side .side-menu .side-menu-button:hover, .gnb-white .side .side-menu .side-menu-button:hover {
  cursor: pointer;
}
.gnb-En {
  position: absolute;
  right: 271px;
  width: 122px;
  height: 44px;
  border-radius: 9px;
  display: flex;
  background: var(--Midium-gray, #9e9e9e);
  transition: 0.5s ease;
}
.gnb-En .kr-btn, .gnb-En .en-btn {
  width: 61px;
  height: 44px;
  background: var(--Midium-gray, #9e9e9e);
  border-radius: 7px;
  color: #f0f0f0;
  font-size: 20px;
  font-family: Pretendard;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.3;
  letter-spacing: normal;
  text-align: center;
}
.gnb-En .en-btn, .gnb-En .gnb-Kr .kr-btn, .gnb-Kr .gnb-En .kr-btn {
  border-radius: 7px;
  background: var(--Light-gray, #f0f0f0);
  color: black;
  font-size: 20px;
  font-family: Pretendard;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.3;
  letter-spacing: normal;
}
.gnb-En:hover {
  cursor: pointer;
}
.gnb-Kr {
  position: absolute;
  transition: 0.5s ease;
  right: 271px;
  width: 122px;
  height: 44px;
  border-radius: 9px;
  display: flex;
  background: var(--Light-gray, #9e9e9e);
}
.gnb-Kr .en-btn, .gnb-Kr .kr-btn {
  width: 61px;
  height: 44px;
  border-radius: 7px;
  background: var(--Light-gray, #9e9e9e);
  color: #f0f0f0;
  font-size: 20px;
  font-family: Pretendard;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.3;
  letter-spacing: normal;
  text-align: center;
}
.gnb-Kr .kr-btn, .gnb-Kr .gnb-En .en-btn, .gnb-En .gnb-Kr .en-btn {
  border-radius: 7px;
  background: var(--Dark-gray, #f0f0f0);
  color: black;
  font-size: 20px;
  font-family: Pretendard;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.3;
  letter-spacing: normal;
}
.gnb-Kr:hover {
  cursor: pointer;
}
.gnb-join {
  display: flex;
  width: 158px;
  height: 44px;
  position: absolute;
  right: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--Light-gray, #f0f0f0);
  color: black;
  font-size: 20px;
  font-family: Pretendard;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.3;
  letter-spacing: normal;
}
.gnb-join:hover {
  cursor: pointer;
  background: var(--Light-gray-Hovered, #e1e1e1);
}
.gnb-language {
  display: none;
  position: absolute;
  right: 30px;
}
.gnb-language:hover {
  cursor: pointer;
}
.gnb .globeBox, .gnb-white .globeBox {
  display: none;
  position: absolute;
  right: 30px;
  top: 48px;
  width: 51px;
  height: 60px;
  border: 0.5px solid var(--Light-gray, #f0f0f0);
  background-color: black;
}
.gnb .globeBox-kr, .gnb-white .globeBox-kr, .gnb .globeBox-en, .gnb-white .globeBox-en {
  height: 30px;
  color: #fff;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  text-align: center;
}
.gnb .globeBox-en, .gnb-white .globeBox-en {
  border-bottom: 0.5px solid var(--Light-gray, #f0f0f0);
}
.gnb .globeBox:hover, .gnb-white .globeBox:hover {
  cursor: pointer;
}

.gnb-white {
  background: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.gnb-white .gnb-menu {
  color: #000;
  text-align: center;
  /* web. light.20 */
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px; /* 140% */
}
.gnb-white .gnb-tiny {
  color: var(--Dark-gray, #3c3c3c);
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px; /* 175% */
}

@media (max-width: 1400px) {
  .gnb, .gnb-white {
    width: 100%;
    height: 77px;
    background: rgba(60, 60, 60, 0.8);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    flex-direction: row;
    position: fixed;
    z-index: 100;
  }
  .gnb-logo {
    left: 15.5%;
  }
  .gnb-menu {
    width: 454px;
    height: 31px;
    left: 28%;
    color: white;
    font-size: 15px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 32px;
    letter-spacing: normal;
  }
  .gnb .gnb-tiny, .gnb-white .gnb-tiny {
    display: none;
  }
  .gnb-En {
    right: 151px;
    width: 60px;
    height: 25px;
    border-radius: 5px;
  }
  .gnb-En .kr-btn, .gnb-En .en-btn {
    width: 30px;
    height: 25px;
    color: white;
    font-size: 12px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.3;
    letter-spacing: normal;
  }
  .gnb-En .en-btn, .gnb-En .gnb-Kr .kr-btn, .gnb-Kr .gnb-En .kr-btn {
    border-radius: 5px;
    color: black;
    font-size: 12px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.3;
    letter-spacing: normal;
  }
  .gnb-Kr {
    right: 151px;
    width: 60px;
    height: 25px;
    border-radius: 5px;
  }
  .gnb-Kr .en-btn, .gnb-Kr .kr-btn {
    width: 30px;
    height: 25px;
    border-radius: 6px;
    color: white;
    font-size: 12px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.3;
    letter-spacing: normal;
  }
  .gnb-Kr .kr-btn, .gnb-Kr .gnb-En .en-btn, .gnb-En .gnb-Kr .en-btn {
    border-radius: 6px;
    color: black;
    font-size: 12px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.3;
    letter-spacing: normal;
  }
  .gnb-join {
    width: 68px;
    height: 25px;
    right: 50px;
    border-radius: 5px;
    color: black;
    font-size: 12px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.3;
    letter-spacing: normal;
  }
  .gnb-white {
    background: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
  }
  .gnb-white .gnb-menu {
    color: #000;
    text-align: center;
    /* web. light.20 */
    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px; /* 140% */
  }
  .gnb-white .gnb-tiny {
    display: none;
  }
}
@media (max-width: 1023px) {
  .gnb, .gnb-white {
    width: 100%;
    height: 57px;
    background: rgba(60, 60, 60, 0.8);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    flex-direction: row;
    position: fixed;
    z-index: 100;
  }
  .gnb-newlogo {
    left: 30px;
    width: 80px;
  }
  .gnb-logo {
    left: 130px;
    width: 80px;
  }
  .gnb-menu {
    width: 304px;
    height: 31px;
    left: 244px;
    color: white;
    font-size: 13px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 32px;
    letter-spacing: normal;
  }
  .gnb .side, .gnb-white .side {
    margin-top: 156vh;
  }
  .gnb-En {
    right: 131px;
    width: 60px;
    height: 25px;
    border-radius: 6px;
  }
  .gnb-En .kr-btn, .gnb-En .en-btn {
    width: 30px;
    height: 25px;
    color: white;
    font-size: 11px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
  }
  .gnb-En .en-btn, .gnb-En .gnb-Kr .kr-btn, .gnb-Kr .gnb-En .kr-btn {
    border-radius: 6px;
    color: black;
    font-size: 11px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
  }
  .gnb-Kr {
    right: 131px;
    width: 60px;
    height: 25px;
    border-radius: 6px;
  }
  .gnb-Kr .en-btn, .gnb-Kr .kr-btn {
    width: 30px;
    height: 25px;
    border-radius: 6px;
    color: white;
    font-size: 11px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
  }
  .gnb-Kr .kr-btn, .gnb-Kr .gnb-En .en-btn, .gnb-En .gnb-Kr .en-btn {
    border-radius: 6px;
    color: black;
    font-size: 11px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
  }
  .gnb-join {
    width: 68px;
    height: 25px;
    right: 30px;
    border-radius: 7px;
    color: black;
    font-size: 12px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.3;
    letter-spacing: normal;
  }
  .gnb-white {
    background: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
  }
  .gnb-white .gnb-menu {
    color: #000;
    text-align: center;
    /* web. light.20 */
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px; /* 140% */
  }
}
@media (max-width: 767px) {
  .gnb, .gnb-white {
    width: 100%;
    height: 57px;
    background: rgba(60, 60, 60, 0.8);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    flex-direction: row; /* 주석 해제해서 활성화 */
    position: fixed;
    z-index: 100;
    justify-content: center;
  }
  .gnb-newlogo {
    display: none;
  }
  .gnb-logo {
    position: relative;
    left: 0px;
  }
  .gnb-bar {
    display: block;
  }
  .gnb .side, .gnb-white .side {
    display: block;
    position: absolute;
    left: 0px;
  }
  .gnb-menu {
    display: none;
    width: 304px;
    height: 31px;
    left: 144px;
    color: white;
    font-size: 13px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 32px;
    letter-spacing: normal;
  }
  .gnb-En {
    display: none;
  }
  .gnb-Kr {
    display: none;
  }
  .gnb-join {
    display: none;
  }
  .gnb-language {
    display: block;
  }
  .gnb .globeBox, .gnb-white .globeBox {
    display: block;
  }
  .gnb-white {
    background: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
  }
  .gnb-white .gnb-menu {
    color: #000;
    text-align: center;
    /* web. light.20 */
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px; /* 140% */
  }
}/*# sourceMappingURL=gnb.styles.css.map */