.home {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.home * {
  word-break: keep-all;
}
.home-anime {
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}
.home-anime video {
  width: 100%;
  margin-top: 77px;
}
.home .intro-background {
  width: 100%;
  background-color: black;
}
.home-intro {
  width: 1192px;
  height: 1272px;
  background-color: black;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
.home-intro-what {
  margin-top: 55px;
  width: 948px;
  height: 364px;
  color: white;
  display: grid;
  grid-template-columns: 1fr 2.02fr;
  grid-template-rows: 1.5fr 1fr;
  position: relative;
  grid-template-areas: "head head" "about body";
}
.home-intro-what-head {
  align-self: end;
  color: white;
  font-size: 60px;
  font-family: Pretendard;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 70px;
  letter-spacing: normal;
  grid-area: head;
  margin-bottom: 42px;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
          hyphens: auto;
}
.home-intro-what-about {
  color: white;
  font-size: 22px;
  font-family: Pretendard;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  height: 28px;
  grid-area: about;
}
.home-intro-what-body {
  color: white;
  font-size: 30px;
  font-family: Pretendard;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 36.5px;
  letter-spacing: normal;
  grid-area: body;
  width: 644px;
  height: 106px;
  word-wrap: break-word;
}
.home-intro-goal {
  width: 1040px;
  height: 298px;
  display: grid;
  grid-template-rows: 98px 116px 84px;
  position: relative;
  margin-top: 150px;
  grid-template-areas: "head" "body1" "body2";
}
.home-intro-goal-head {
  color: white;
  font-size: 60px;
  font-family: Pretendard;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  grid-area: head;
  width: 668px;
  height: 28px;
}
.home-intro-goal-body1, .home-intro-goal-body2 {
  color: white;
  font-size: 22px;
  font-family: Pretendard;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  width: 584px;
  height: 84px;
  grid-area: body1;
  justify-self: end;
}
.home-intro-goal-body2 {
  grid-area: body2;
}
.home-intro-contact {
  color: white;
  position: relative;
  width: 1192px;
  height: 160px;
  margin-top: 150px;
}
.home-intro-contact-head {
  color: white;
  font-size: 30px;
  font-family: Pretendard;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  margin-bottom: 16px;
}
.home-intro-contact-email {
  background-color: black;
  width: 1192px;
  height: 44px;
  margin-bottom: 16px;
}
.home-intro-contact-email input {
  width: 987px;
  height: 44px;
  border-radius: 10px;
  border: 1px solid var(--Light-gray, #f0f0f0);
  background-color: black;
  color: #f0f0f0;
  font-size: 20px;
  font-family: Pretendard;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  padding-left: 21px;
  padding-right: 21px;
  margin-right: 21px;
}
.home-intro-contact-email .button, .home-intro-contact-email .button-active {
  border-radius: 10px;
  width: 184px;
  height: 44px;
  background: var(--Light-gray, #f0f0f0);
  color: #3c3c3c;
  font-size: 20px;
  font-family: Pretendard;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
}
.home-intro-contact-email .button-active:hover {
  cursor: pointer;
  background: var(--Light-gray, #e1e1e1);
}
.home-intro-contact-body {
  color: #9e9e9e;
  font-size: 22px;
  font-family: Pretendard;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
}
.home-research {
  position: relative;
  width: 1192px;
  height: 824px;
  background-color: white;
  margin-bottom: 150px;
  margin: auto;
}
.home-research-head {
  position: relative;
  margin-top: 142px;
  width: 1192px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-research-head span {
  color: black;
  font-size: 60px;
  font-family: Pretendard;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
}
.home-research-head button {
  width: 161px;
  height: 44px;
  background: var(--Light-gray, #fff);
  border-radius: 10px;
  border: 1px solid var(--Dark-gray, #3c3c3c);
  color: #3c3c3c;
  font-size: 20px;
  font-family: Pretendard;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
}
.home-research-head button:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.home-orcapop {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 30;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(8.5px);
          backdrop-filter: blur(8.5px);
}
.home-orcapop-box {
  width: 32.3vw;
  height: 7.4vw;
  margin-top: 23vw;
  border-radius: 0.625vw;
  background: var(--Light-gray, #f0f0f0);
  display: inline-flex;
  padding: 1.04vw;
  flex-direction: column;
  align-items: center;
  gap: 0.6vw;
}
.home-orcapop-box .text {
  color: #000;
  text-align: center;
  /* web. light.20 */
  font-family: Pretendard;
  font-size: 1.04vw;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4; /* 140% */
}
.home-orcapop-box button {
  display: flex;
  width: 30.2vw;
  padding: 0.4vw 2.4vw;
  justify-content: center;
  align-items: center;
  gap: 0.52vw;
  border-radius: 0.52vw;
  background: var(--Dark-gray, #3c3c3c);
  color: #fff;
  /* web. light.20 */
  font-family: Pretendard;
  font-size: 1.04vw;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4; /* 140% */
}
.home-orcapop-box button:hover {
  cursor: pointer;
}

@media (max-width: 1400px) {
  .home {
    width: 100%;
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
  }
  .home .intro-background {
    width: 100%;
    background-color: black;
  }
  .home-intro {
    width: 900px;
  }
  .home-intro-what {
    width: 771px;
    height: 250px;
  }
  .home-intro-what-head {
    color: white;
    font-size: 45px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 60px;
    letter-spacing: normal;
  }
  .home-intro-what-about {
    font-size: 18px;
    margin-top: 20px;
  }
  .home-intro-what-body {
    width: 542px;
    margin-top: 20px;
    color: white;
    font-size: 24px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
  .home-intro-goal {
    width: 771px;
    height: 300px;
  }
  .home-intro-goal-head {
    color: white;
    font-size: 45px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
  .home-intro-goal-body1, .home-intro-goal-body2 {
    width: 550px;
    margin-left: 60px;
  }
  .home-intro-goal-body2 {
    width: 550px;
    margin-left: 0px;
  }
  .home-intro-contact {
    color: white;
    position: relative;
    width: 900px;
    height: 160px;
    margin-top: 150px;
  }
  .home-intro-contact-head {
    color: white;
    font-size: 25px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
    margin-bottom: 16px;
  }
  .home-intro-contact-email {
    background-color: black;
    width: 900px;
    height: 44px;
    margin-bottom: 16px;
  }
  .home-intro-contact-email input {
    width: 750px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid var(--Light-gray, #f0f0f0);
    background-color: black;
    color: #f0f0f0;
    font-size: 20px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
    padding-left: 21px;
    margin-right: 20px;
  }
  .home-intro-contact-email .button, .home-intro-contact-email .button-active {
    border-radius: 10px;
    width: 184px;
    height: 44px;
    width: 130px;
    background: var(--Light-gray, #f0f0f0);
    color: #3c3c3c;
    font-size: 20px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
  .home-intro-contact-body {
    color: #9e9e9e;
    font-size: 16px;
    font-family: Pretendard;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
  .home-research {
    position: relative;
    width: 900px;
    height: 750px;
    background-color: white;
    margin-bottom: 150px;
    margin: auto;
  }
  .home-research-head {
    position: relative;
    margin-top: 142px;
    width: 900px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .home-research-head span {
    color: black;
    font-size: 45px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
  .home-research-head button {
    width: 161px;
    height: 44px;
    border-radius: 10px;
    margin-left: 100px;
    border: 1px solid var(--Dark-gray, #3c3c3c);
    color: #3c3c3c;
    font-size: 20px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
}
@media (max-width: 1023px) {
  .home {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .home-anime video {
    margin-top: 55px;
  }
  .home .intro-background {
    width: 100%;
    background-color: black;
  }
  .home-intro {
    width: 700px;
  }
  .home-intro-what {
    width: 600px;
    height: 250px;
    display: flex;
    flex-direction: column;
  }
  .home-intro-what-head {
    align-self: start;
    margin-bottom: 0px;
    color: white;
    font-size: 30px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 45px;
    letter-spacing: normal;
  }
  .home-intro-what-about {
    margin-top: 30px;
  }
  .home-intro-what-body {
    width: 500px;
    margin-top: 20px;
    color: white;
    font-size: 18px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
  .home-intro-goal {
    width: 600px;
    height: 250px;
    display: flex;
    flex-direction: column;
  }
  .home-intro-goal-head {
    color: white;
    font-size: 30px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
  .home-intro-goal-body1, .home-intro-goal-body2 {
    width: 500px;
    margin-top: 50px;
    margin-left: 0px;
    color: white;
    font-size: 18px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
  .home-intro-goal-body2 {
    width: 500px;
    margin-top: 15px;
    color: white;
    font-size: 18px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
  .home-intro-contact {
    color: white;
    position: relative;
    width: 700px;
    height: 160px;
    margin-top: 150px;
  }
  .home-intro-contact-head {
    color: white;
    font-size: 20px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
    margin-bottom: 16px;
  }
  .home-intro-contact-email {
    background-color: black;
    width: 700px;
    height: 44px;
    margin-bottom: 16px;
  }
  .home-intro-contact-email input {
    width: 550px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid var(--Light-gray, #f0f0f0);
    background-color: black;
    color: #f0f0f0;
    font-size: 20px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
    padding-left: 21px;
    margin-right: 20px;
  }
  .home-intro-contact-email .button, .home-intro-contact-email .button-active {
    border-radius: 10px;
    width: 184px;
    height: 44px;
    width: 130px;
    background: var(--Light-gray, #f0f0f0);
    color: #3c3c3c;
    font-size: 20px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
  .home-intro-contact-body {
    color: #9e9e9e;
    font-size: 14px;
    font-family: Pretendard;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
  .home-research {
    position: relative;
    width: 700px;
    height: 650px;
    background-color: white;
    margin-bottom: 150px;
    margin: auto;
  }
  .home-research-head {
    position: relative;
    margin-top: 142px;
    width: 700px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .home-research-head span {
    color: black;
    font-size: 45px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
  .home-research-head button {
    width: 161px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid var(--Dark-gray, #3c3c3c);
    color: #3c3c3c;
    font-size: 20px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
}
@media (max-width: 767px) {
  .home {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .home .intro-background {
    width: 100%;
    background-color: black;
  }
  .home-intro {
    width: 350px;
  }
  .home-intro-what {
    width: 350px;
    height: 250px;
    display: flex;
    flex-direction: column;
  }
  .home-intro-what-head {
    color: white;
    font-size: 19px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
  .home-intro-what-about {
    margin-top: 30px;
    font-size: 17px;
    font-size: 12px;
  }
  .home-intro-what-body {
    width: 300px;
    margin-top: 10px;
    color: white;
    font-size: 13px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
  }
  .home-intro-goal {
    width: 350px;
    height: 350px;
    display: flex;
    flex-direction: column;
  }
  .home-intro-goal-head {
    width: 350px;
    color: white;
    font-size: 19px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
  .home-intro-goal-body1, .home-intro-goal-body2 {
    width: 350px;
    margin-top: 30px;
    height: -moz-fit-content;
    height: fit-content;
    color: white;
    font-size: 12px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
  }
  .home-intro-goal-body2 {
    width: 350px;
    margin-top: 15px;
    height: -moz-fit-content;
    height: fit-content;
    color: white;
    font-size: 12px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
  }
  .home-intro-contact {
    color: white;
    position: relative;
    width: 350px;
    height: 160px;
    margin-top: 150px;
  }
  .home-intro-contact-head {
    color: white;
    font-size: 15px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
    margin-bottom: 16px;
  }
  .home-intro-contact-email {
    background-color: black;
    width: 350px;
    height: 44px;
    margin-bottom: 16px;
  }
  .home-intro-contact-email input {
    width: 240px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid var(--Light-gray, #f0f0f0);
    background-color: black;
    color: #f0f0f0;
    font-size: 12px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
    padding-left: 20px;
    margin-right: 20px;
  }
  .home-intro-contact-email .button, .home-intro-contact-email .button-active {
    border-radius: 10px;
    width: 90px;
    height: 44px;
    background: var(--Light-gray, #f0f0f0);
    color: #3c3c3c;
    font-size: 14px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
  .home-intro-contact-body {
    color: #9e9e9e;
    font-size: 12px;
    font-family: Pretendard;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
  }
  .home-research {
    position: relative;
    width: 350px;
    height: 480px;
    background-color: white;
    margin-bottom: 150px;
    margin: auto;
  }
  .home-research-head {
    position: relative;
    margin-top: 142px;
    width: 350px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .home-research-head span {
    color: black;
    font-size: 20px;
    font-family: Pretendard;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }
  .home-research-head button {
    width: 70px;
    height: 24px;
    border-radius: 5px;
    border: 1px solid var(--Dark-gray, #3c3c3c);
    color: #3c3c3c;
    font-size: 11px;
    font-family: Pretendard;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: normal;
  }
}
.Section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 100px auto;
  width: 1192px;
}

.SectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  color: #000;
  font-family: Pretendard;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 80px;
  opacity: 0.9;
  text-align: start;
}

.ViewButton {
  width: 161px;
  height: 44px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid var(--Dark-gray, #3c3c3c);
  color: var(--Dark-gray, #3c3c3c);
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
}
.ViewButton:hover {
  cursor: pointer;
  background: var(--Light-gray, #f0f0f0);
}

.ProjectBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.EventSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10px auto;
  width: 1192px;
  overflow: hidden;
}

.EventBox {
  overflow: hidden;
}

.EventBoxSlide {
  width: "100%";
  overflow-x: "hidden";
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  transform: translate(0px);
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  user-select: none;
}
.EventBoxSlide.InsightEmpty > div {
  margin-right: 20px;
}
.EventBoxSlide.InsightFull {
  justify-content: space-between;
}

@media (max-width: 1400px) {
  .EventBox {
    width: 900px;
  }
  .EventBoxSlide {
    width: 900px;
  }
}
@media (max-width: 1023px) {
  .EventBox {
    width: 700px;
  }
  .EventBoxSlide {
    width: 700px;
  }
}
@media (max-width: 767px) {
  .EventBox {
    width: 350px;
  }
  .EventBoxSlide {
    width: 350px;
  }
  .EventBoxSlide.InsightEmpty > div {
    margin-right: 9px;
  }
  .ViewButton {
    width: 70px;
    height: 24px;
    border-radius: 5px;
    font-size: 11px;
    line-height: 25px;
  }
}/*# sourceMappingURL=home.styles.css.map */