@mixin font-setting($color, $font-weight: normal, $font-size, $line-height) {
	color: $color;
	font-size: $font-size;
	font-family: Pretendard;
	@if $font-weight != normal {
		font-weight: $font-weight;
	}
	font-stretch: normal;
	font-style: normal;
	line-height: $line-height;
	letter-spacing: normal;
}

.researcher-map {
	position: relative;
	width: 1192px;
	height: 596px;
	margin-top: 40px;
	//margin-left: 18.9583%;
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	grid-template-rows: repeat(4, 1fr);
	& * {
		word-break: keep-all;
	}
	svg {
		width: 1126px;
		height: 575px;
		margin-top: 16.8px;
		margin-left: 42px;
		opacity: 0.5;
		//mix-blend-mode: multiply;
	}
	.research-box-container {
		position: absolute;
		display: flex;
		flex-wrap: wrap;
	}

	.country0 {
		position: relative;
		width: 149px;
		height: 149px;
		.country-box {
			width: 149px;
			height: 149px;
			border-top: 1px solid var(--Midium-gray, #9e9e9e);
			border-left: 1px solid var(--Midium-gray, #9e9e9e);
			mix-blend-mode: multiply;
		}
		.country-box:hover {
			background: var(--Midium-gray, #9e9e9e);
			//opacity: 0.7;
			transition: 0.3s;
			cursor: pointer;
		}
		.country-box-Off {
			width: 149px;
			height: 149px;
			border-top: 1px solid var(--Midium-gray, #9e9e9e);
			border-left: 1px solid var(--Midium-gray, #9e9e9e);
			mix-blend-mode: multiply;
		}
		.researchers {
			position: absolute;
			width: 247px;
			height: fit-content;
			z-index: -10;
			// top: 0px;
			// left: 140px;
			//border: 1px solid var(--Midium-gray, #9e9e9e);
			display: grid;
			grid-template-columns: 18px 211px 18px;
			grid-template-rows: 33px fit-content 16px;
			//display: none;
			opacity: 0;
			transform: translateY(-20px);
			/* transition 적용 */
			transition: opacity 0.3s ease, transform 0.3s ease;
			&-head {
				grid-area: head;
				@include font-setting(#3c3c3c, 400, 20px, 28px);
			}
			&-back {
				grid-area: back;
				place-self: center center;
			}
			&-back:hover {
				cursor: pointer;
			}
			&-body {
				width: 211px;
				height: fit-content;
				//background-color: rebeccapurple;
				grid-area: body;
				.researcher-card {
					width: 211px;
					height: 108px;
					display: flex;
					align-items: center;
					margin-bottom: 10px;

					background: var(--Dark-gray, #3c3c3c);
					img {
						width: 92px;
						height: 92px;

						margin-left: 8px;
					}
					span {
						width: 45%;
						//height: 60%;
						height: fit-content;
						max-height: 70%;
						align-self: end;
						word-wrap: break-word;
						overflow: hidden;
						margin-left: 8px;
						//white-space: nowrap;
						text-overflow: ellipsis;
						margin-top: 8px;
						margin-bottom: 8px;
						@include font-setting(white, 600, 15px, 20px);
					}
					&:hover {
						cursor: pointer;
					}
				}
			}
			&-go {
				grid-area: go;
				place-self: center center;
			}
			&-go:hover {
				cursor: pointer;
			}
			&-dot {
				grid-area: dots;
				place-self: center center;
				margin-left: 4px;
				img {
					margin-right: 8px;
				}
			}
			grid-template-areas:
				'. head .'
				'back body go'
				'. dots .';
		}
		.researchers-active {
			@extend .researchers;
			opacity: 1;
			transform: translateY(0);
			z-index: 100;
		}
	}

	.country1 {
		@extend .country0;
		border-right: 1px solid var(--Midium-gray, #9e9e9e);
	}
	.country2 {
		@extend .country0;
		border-bottom: 1px solid var(--Midium-gray, #9e9e9e);
	}
	.country3 {
		@extend .country0;
		border-right: 1px solid var(--Midium-gray, #9e9e9e);
		border-bottom: 1px solid var(--Midium-gray, #9e9e9e);
	}
}

.researcher-map-black {
	margin-top: 0px;
	position: relative;
	width: 1320px;
	height: 660px;
	margin-top: 40px;
	//margin-left: 18.9583%;
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	grid-template-rows: repeat(4, 1fr);
	& * {
		word-break: keep-all;
	}
	svg {
		width: 1245px;
		height: 636px;
		margin-top: 16.8px;
		margin-left: 42px;
		opacity: 0.5;
		//mix-blend-mode: multiply;
	}
	.research-box-container {
		position: absolute;
		display: flex;
		flex-wrap: wrap;
	}

	.country0 {
		position: relative;
		width: 165px;
		height: 165px;
		border-top: 1px solid var(--Midium-gray, #9e9e9e);
		border-left: 1px solid var(--Midium-gray, #9e9e9e);
		//mix-blend-mode: multiply;

		.country-box {
			width: 165px;
			height: 165px;
			z-index: 50;
			border-top: 1px solid var(--Midium-gray, #9e9e9e);
			border-left: 1px solid var(--Midium-gray, #9e9e9e);
			//
		}
		.country-box:hover {
			background: var(--Midium-gray, #9e9e9e);
			//opacity: 0.7;
			transition: 0.3s;
			cursor: pointer;
		}
		.country-box-Off {
			width: 165px;
			height: 165px;
			border-top: 1px solid var(--Midium-gray, #9e9e9e);
			border-left: 1px solid var(--Midium-gray, #9e9e9e);
			mix-blend-mode: multiply;
		}
		.researchers {
			display: none;
			position: absolute;

			z-index: -10;
			top: -33px;
			left: 165px;
			//border: 1px solid var(--Midium-gray, #9e9e9e);
			display: grid;
			width: 247px;
			height: fit-content;
			grid-template-columns: 18px 211px 18px;
			grid-template-rows: 33px fit-content 16px;
			opacity: 0;
			transform: translateY(-20px);

			/* transition 적용 */
			transition: opacity 0.3s ease, transform 0.3s ease;

			&-head {
				grid-area: head;
				color: #fff;

				font-family: Pretendard;
				font-size: 22px;
				font-style: normal;
				font-weight: 400;
				line-height: 28px; /* 127.273% */
			}
			&-back {
				grid-area: back;
				place-self: center center;
			}
			&-back:hover {
				cursor: pointer;
			}
			&-body {
				width: 211px;
				height: fit-content;
				grid-area: body;
				.researcher-card {
					position: relative;
					width: 211px;
					height: 108px;
					display: flex;
					align-items: center;
					margin-bottom: 10px;
					background-color: #fff;
					img {
						width: 92px;
						height: 92px;
						margin-left: 8px;
					}
					span {
						width: 45%;
						//height: 60%;
						height: fit-content;
						max-height: 70%;
						align-self: end;
						word-wrap: break-word;
						overflow: hidden;
						margin-left: 8px;
						//white-space: nowrap;
						text-overflow: ellipsis;
						margin-top: 8px;
						margin-bottom: 8px;
						@include font-setting(black, 600, 14px, 17px);
					}
					&:hover {
						cursor: pointer;
					}
				}
			}
			&-go {
				grid-area: go;
				place-self: center center;
			}
			&-go:hover {
				cursor: pointer;
			}
			&-dot {
				grid-area: dots;
				place-self: center center;
				margin-left: 4px;
				img {
					margin-right: 8px;
				}
			}
			grid-template-areas:
				'. head .'
				'back body go'
				'. dots .';
		}
		.researchers-active {
			@extend .researchers;
			opacity: 1;
			transform: translateY(0);
			z-index: 100;
		}
	}

	.country1 {
		@extend .country0;
		border-right: 1px solid var(--Midium-gray, #9e9e9e);
	}
	.country2 {
		@extend .country0;
		border-bottom: 1px solid var(--Midium-gray, #9e9e9e);
	}
	.country3 {
		@extend .country0;
		border-right: 1px solid var(--Midium-gray, #9e9e9e);
		border-bottom: 1px solid var(--Midium-gray, #9e9e9e);
	}
}

@media (max-width: 1400px) {
	.researcher-map {
		//margin-top: 0px;
		//@extend .researcher-map;
		svg {
			width: 850px;
			height: 434px;
			margin-top: 12.68px;
			margin-left: 31.71px;
		}
		width: 900px;
		height: 450px;

		.country0 {
			width: 112.5px;
			height: 112.5px;

			.country-box {
				width: 112.5px;
				height: 112.5px;
				z-index: 50;
			}
			.country-box:hover {
				background: var(--Midium-gray, #9e9e9e);
				//opacity: 0.7;

				cursor: pointer;
			}
			.country-box-Off {
				width: 112.5px;
				height: 112.5px;
			}
			.researchers {
				width: 186.5px;
				//height: 296.7px;
				grid-template-columns: 13.6px 159.3px 13.6px;
				grid-template-rows: 25px fit-content 12.1px;
				&-head {
					grid-area: head;
					//color: #fff;

					/* web. re.22 */
					font-family: Pretendard;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 28px; /* 127.273% */
				}
				&-back {
					grid-area: back;
					place-self: center center;
					width: 8px;
					height: 13.6px;
				}
				&-body {
					width: 159.3px;
					height: fit-content;
					//height: 259.7px;
					grid-area: body;
					.researcher-card {
						width: 159.3px;
						height: 80px;
						display: flex;
						margin-bottom: 10px;
						//background-color: #fff;
						img {
							width: 69.5px;
							height: 69.5px;
							margin-left: 5px;
						}
						span {
							margin-left: 4px;
							margin-bottom: 5px;
							@include font-setting(white, 600, 12px, 15px);
						}
					}
				}
				&-go {
					grid-area: go;
					place-self: center center;
					width: 8px;
					height: 13.6px;
				}
			}
		}
	}
	.researcher-map-black {
		margin-top: 0px;
		//@extend .researcher-map;
		svg {
			width: 940px;
			height: 480.2px;
			margin-top: 12.68px;
			margin-left: 31.71px;
		}
		width: 996.64px;
		height: 498px;

		.country0 {
			width: 124.58px;
			height: 124.58px;
			border-top: 1px solid var(--Midium-gray, #9e9e9e);
			border-left: 1px solid var(--Midium-gray, #9e9e9e);
			.country-box {
				width: 124.58px;
				height: 124.58px;
				z-index: 50;
			}
			.country-box:hover {
				background: var(--Midium-gray, #9e9e9e);
				//opacity: 0.7;

				cursor: pointer;
			}
			.country-box-Off {
				width: 124.58px;
				height: 124.58px;
			}
			.researchers {
				width: 186.5px;
				//height: 296.7px;
				grid-template-columns: 13.6px 159.3px 13.6px;
				grid-template-rows: 25px fit-content 12.1px;
				&-head {
					grid-area: head;
					color: #fff;

					/* web. re.22 */
					font-family: Pretendard;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 28px; /* 127.273% */
				}
				&-back {
					grid-area: back;
					width: 8px;
					height: 13.6px;
					place-self: center center;
				}
				&-body {
					width: 159.3px;
					height: fit-content;
					grid-area: body;
					.researcher-card {
						width: 159.3px;
						height: 80px;
						display: flex;
						margin-bottom: 10px;
						background-color: #fff;
						img {
							width: 69.5px;
							height: 69.5px;
							margin-left: 5px;
						}
						span {
							margin-left: 4px;
							margin-bottom: 5px;
							@include font-setting(black, 600, 12px, 15px);
						}
					}
				}
				&-go {
					grid-area: go;
					width: 8px;
					height: 13.6px;
					place-self: center center;
				}

				grid-template-areas:
					'. head .'
					'back body go'
					'. dots .';
			}
		}

		.country1 {
			//@extend .country0;
			border-right: 1px solid var(--Midium-gray, #9e9e9e);
		}
		.country2 {
			//@extend .country0;
			border-bottom: 1px solid var(--Midium-gray, #9e9e9e);
		}
		.country3 {
			//@extend .country0;
			border-right: 1px solid var(--Midium-gray, #9e9e9e);
			border-bottom: 1px solid var(--Midium-gray, #9e9e9e);
		}
	}
}

@media (max-width: 1023px) {
	.researcher-map-black {
		margin-top: 0px;
		//@extend .researcher-map;
		svg {
			width: 731.11px;
			height: 373.49px;
			margin-top: 9.86px;
			margin-left: 24.66px;
		}
		width: 780.16px;
		height: 498px;

		.country0 {
			width: 96.9px;
			height: 96.9px;
			border-top: 1px solid var(--Midium-gray, #9e9e9e);
			border-left: 1px solid var(--Midium-gray, #9e9e9e);
			.country-box {
				width: 96.9px;
				height: 96.9px;
				z-index: 50;
			}
			.country-box:hover {
				background: var(--Midium-gray, #9e9e9e);
				//opacity: 0.7;

				cursor: pointer;
			}
			.country-box-Off {
				width: 96.9px;
				height: 96.9px;
			}
			.researchers {
				width: 145px;
				//height: 230.7px;
				grid-template-columns: 10.6px 123.9px 10.6px;
				grid-template-rows: 19.4px fit-content 9.4px;
				&-head {
					grid-area: head;
					color: #fff;
					width: 140px;
					/* web. re.22 */
					font-family: Pretendard;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 28px; /* 127.273% */
				}
				&-back {
					grid-area: back;
					place-self: center center;
				}
				&-body {
					width: 124px;
					//height: 202px;
					grid-area: body;
					.researcher-card {
						width: 124px;
						height: 61px;
						display: flex;
						margin-bottom: 5px;
						background-color: #fff;
						img {
							width: 54px;
							height: 54px;

							margin-left: 4px;
						}
						span {
							width: 55px;
							//display: flex;

							font-size: 10px;
							margin-bottom: 3.5px;
							line-height: 11px;
						}
					}
				}
				&-go {
					grid-area: go;
					place-self: center center;
				}
			}
		}

		.country1 {
			//@extend .country0;
			border-right: 1px solid var(--Midium-gray, #9e9e9e);
		}
		.country2 {
			//@extend .country0;
			border-bottom: 1px solid var(--Midium-gray, #9e9e9e);
		}
		.country3 {
			//@extend .country0;
			border-right: 1px solid var(--Midium-gray, #9e9e9e);
			border-bottom: 1px solid var(--Midium-gray, #9e9e9e);
		}
	}
	.researcher-map {
		//margin-top: 0px;
		//@extend .researcher-map;
		svg {
			width: 661px;
			height: 337.66px;
			margin-top: 9.86px;
			margin-left: 24.66px;
		}
		width: 700px;
		height: 350px;

		.country0 {
			width: 87.5px;
			height: 87.5px;
			.country-box {
				width: 87.5px;
				height: 87.5px;
				z-index: 50;
			}
			.country-box:hover {
				background: var(--Midium-gray, #9e9e9e);
				//opacity: 0.7;

				cursor: pointer;
			}
			.country-box-Off {
				width: 87.5px;
				height: 87.5px;
			}
			.researchers {
				width: 145px;
				//height: 230.7px;
				grid-template-columns: 10.6px 123.9px 10.6px;
				grid-template-rows: 19.4px fit-content 9.4px;
				&-head {
					grid-area: head;
					//color: #fff;

					/* web. re.22 */
					font-family: Pretendard;
					font-size: 13px;
					font-style: normal;
					font-weight: 400;
					line-height: 28px; /* 127.273% */
				}
				&-back {
					grid-area: back;
					place-self: center center;
				}
				&-body {
					width: 124px;
					//height: 202px;
					grid-area: body;
					.researcher-card {
						width: 124px;
						height: 61px;
						display: flex;
						margin-bottom: 5px;
						//background-color: #fff;
						img {
							width: 54px;
							height: 54px;

							margin-left: 4px;
						}
						span {
							width: 55px;
							//display: flex;

							font-size: 10px;
							margin-bottom: 3.5px;
							line-height: 11px;
						}
					}
				}
				&-go {
					grid-area: go;
					place-self: center center;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.researcher-map-black {
		margin-top: 0px;
		//@extend .researcher-map;
		svg {
			width: 330.6px;
			height: 169px;
			margin-top: 4.9px;
			margin-left: 12.3px;
		}
		width: 350px;
		height: 175px;
		.country0 {
			width: 43.75px;
			height: 43.75px;
			border-top: 1px solid var(--Midium-gray, #9e9e9e);
			border-left: 1px solid var(--Midium-gray, #9e9e9e);
			.country-box {
				width: 43.75px;
				height: 43.75px;
				z-index: 50;
			}
			.country-box:hover {
				background: var(--Midium-gray, #9e9e9e);
				//opacity: 0.7;

				cursor: pointer;
			}
			.country-box-Off {
				width: 43.75px;
				height: 43.75px;
			}
			.researchers {
				width: 120px;
				//height: 174px; //1.69

				grid-template-columns: 10px 100px 10px;
				grid-template-rows: 14px fit-content 10px;
				&-head {
					grid-area: head;
					color: #fff;
					//width: px;
					/* web. re.22 */
					font-family: Pretendard;
					font-size: 11px;
					font-style: normal;
					font-weight: 400;
					line-height: 15px; /* 127.273% */
				}
				&-back {
					grid-area: back;
					place-self: center center;
					width: 6px;
				}
				&-body {
					width: 100px;
					//height: 150px;
					grid-area: body;
					.researcher-card {
						width: 100px;
						height: 40px;
						display: flex;
						flex-direction: row;
						margin-bottom: 3px;
						background-color: #fff;
						align-items: center;
						//justify-content: center;
						img {
							width: 30px;
							height: 30px;
							margin-left: 5px;
						}
						span {
							width: 55px;
							height: fit-content;
							//margin: auto;
							//margin-bottom: 5px;

							//text-align: center;
							margin-left: 5px;
							//margin: auto;
							@include font-setting(black, 600, 8px, 9px);
						}
					}
				}
				&-go {
					grid-area: go;
					place-self: center center;
					width: 6px;
				}
				&-dot {
					margin-left: 1px;
					height: 6px;
					margin-top: -15px;
					img {
						margin-right: 2px;
						height: 3px;
					}
				}
			}
		}

		.country1 {
			//@extend .country0;
			border-right: 1px solid var(--Midium-gray, #9e9e9e);
		}
		.country2 {
			//@extend .country0;
			border-bottom: 1px solid var(--Midium-gray, #9e9e9e);
		}
		.country3 {
			//@extend .country0;
			border-right: 1px solid var(--Midium-gray, #9e9e9e);
			border-bottom: 1px solid var(--Midium-gray, #9e9e9e);
		}
	}
	.map-container {
		width: 100%;
		display: flex;
		justify-content: center;
		.researcher-map {
			margin-top: 30px;

			//@extend .researcher-map;
			svg {
				width: 330.6px;
				height: 169px;
				margin-top: 4.9px;
				margin-left: 12.3px;
			}
			width: 350px;
			height: 175px;

			.country0 {
				width: 43.75px;
				height: 43.75px;
				.country-box {
					width: 43.75px;
					height: 43.75px;
					z-index: 50;
				}
				.country-box:hover {
					background: var(--Midium-gray, #9e9e9e);
					//opacity: 0.7;

					cursor: pointer;
				}
				.country-box-Off {
					width: 43.75px;
					height: 43.75px;
				}
				.researchers {
					width: 120px;
					//height: 174px; //1.69

					grid-template-columns: 10px 100px 10px;
					grid-template-rows: 14px fit-content 10px;
					&-head {
						grid-area: head;
						//color: #fff;
						//width: 90px;
						/* web. re.22 */
						font-family: Pretendard;
						font-size: 11px;
						font-style: normal;
						font-weight: 400;
						line-height: 15px; /* 127.273% */
					}
					&-back {
						grid-area: back;
						place-self: center center;
						width: 6px;
					}
					&-body {
						width: 100px;
						//height: 150px;
						grid-area: body;
						.researcher-card {
							width: 100px;
							height: 40px;
							display: flex;
							margin-bottom: 3px;
							display: flex;
							flex-direction: row;
							align-items: center;
							//background-color: #fff;
							img {
								width: 30px;
								height: 30px;
								margin-left: 5px;
							}
							span {
								width: 55px;
								height: fit-content;
								//margin: auto;
								//margin-bottom: 5px;

								//text-align: center;
								margin-left: 5px;
								//margin: auto;
								@include font-setting(white, 400, 8px, 9px);
							}
						}
					}
					&-go {
						grid-area: go;
						place-self: center center;
						width: 5px;
					}
					&-dot {
						margin-left: 1px;
						height: 6px;
						margin-top: -15px;
						img {
							margin-right: 2px;
							height: 3px;
						}
					}
				}
			}
		}
	}
}
