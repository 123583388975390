.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: all 0.8s ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: all 0.4s ease-out;
}

.entire {
  height: -moz-fit-content;
  height: fit-content;
}
.entire .newspop {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(8.5px);
          backdrop-filter: blur(8.5px);
}
.entire .newspop-box {
  width: 32.3vw;
  height: 7.4vw;
  margin-top: 23vw;
  border-radius: 0.625vw;
  background: var(--Light-gray, #f0f0f0);
  display: inline-flex;
  padding: 1.04vw;
  flex-direction: column;
  align-items: center;
  gap: 0.6vw;
}
.entire .newspop-box .text {
  color: #000;
  text-align: center;
  /* web. light.20 */
  font-family: Pretendard;
  font-size: 1.04vw;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4; /* 140% */
}
.entire .newspop-box button {
  display: flex;
  width: 30.2vw;
  padding: 0.4vw 2.4vw;
  justify-content: center;
  align-items: center;
  gap: 0.52vw;
  border-radius: 0.52vw;
  background: var(--Dark-gray, #3c3c3c);
  color: #fff;
  /* web. light.20 */
  font-family: Pretendard;
  font-size: 1.04vw;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4; /* 140% */
}
.entire .newspop-box button:hover {
  cursor: pointer;
}/*# sourceMappingURL=rootnavigation.style.css.map */