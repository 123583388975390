.footer {
  width: 1192px;
  height: 814px;
  margin: auto;
}
.footer * {
  word-break: keep-all;
}
.footer-news {
  width: 100%;
  height: 166px;
  margin-bottom: 131px;
}
.footer-news-head {
  margin-bottom: 24px;
  color: #000;
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 93.333% */
}
.footer-news form {
  width: 1192px;
  height: 44px;
  margin-bottom: 16px;
}
.footer-news form input {
  width: 987px;
  height: 44px;
  margin-right: 21px;
  padding-left: 21px;
  padding-right: 21px;
  border-radius: 10px;
  border: 1px solid var(--Dark-gray, #3c3c3c);
  color: black;
  font-size: 20px;
  font-family: Pretendard;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
}
.footer-news form .button, .footer-news form .button-active {
  border-radius: 10px;
  width: 184px;
  height: 44px;
  background: var(--Dark-gray, #3c3c3c);
  color: #3c3c3c;
  font-size: 20px;
  font-family: Pretendard;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
}
.footer-news form .button-active:hover {
  cursor: pointer;
  background-color: black;
}
.footer-news-please {
  width: 762px;
  color: var(--Midium-gray, #9e9e9e);
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 127.273% */
}
.footer-info {
  display: flex;
  flex-direction: column;
}
.footer-info-head {
  display: flex;
  flex-direction: row;
  height: 20px;
  margin-bottom: 40px;
  color: #000;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 127.273% */
  text-decoration-line: underline;
}
.footer-info-head .legal {
  margin-right: 29px;
}
.footer-info-beamworks {
  margin-bottom: 20px;
  color: #000;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 127.273% */
}
.footer-info-ceo {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.footer-info-ceo .ceo, .footer-info-ceo .brtext {
  margin-right: 21px;
  color: #000;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 127.273% */
}
.footer-info-ceo .kim, .footer-info-ceo .brnumber {
  color: #000;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 127.273% */
}
.footer-info-ceo .brtext {
  margin-left: 45px;
}
.footer-info-address {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.footer-info-address .business {
  margin-right: 21px;
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 127.273% */
}
.footer-info-address .location {
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 127.273% */
}
.footer-info-number {
  display: flex;
  flex-direction: row;
}
.footer-info-number .number1 {
  margin-right: 21px;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 127.273% */
}
.footer-info-number .number2 {
  margin-right: 49px;
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 127.273% */
}
.footer-info-number .email1 {
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin-right: 21px;
  line-height: 28px; /* 127.273% */
}
.footer-info-number .email2 {
  margin-right: 65px;
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 127.273% */
}
.footer-info-number .fax1 {
  margin-right: 21px;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 127.273% */
}
.footer-info-number .fax2 {
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 127.273% */
}
.footer-info-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
}
.footer-info-logo .beamworks {
  margin-right: 31px;
  margin-top: -2px;
}

@media (max-width: 1400px) {
  .footer {
    width: 900px;
    height: 800px;
  }
  .footer-news {
    width: 900px;
  }
  .footer-news form {
    width: 900px;
  }
  .footer-news form input {
    width: 750px;
    margin-right: 20px;
  }
  .footer-news form .button, .footer-news form .button-active {
    width: 130px;
  }
  .footer-news-please {
    width: 900px;
  }
  .footer-info {
    width: 900px;
  }
  .footer-info-address .business {
    font-size: 15px;
  }
  .footer-info-address .location {
    font-size: 15px;
  }
  .footer-info-number .number1 {
    font-size: 15px;
  }
  .footer-info-number .number2 {
    font-size: 15px;
  }
  .footer-info-number .email1 {
    font-size: 15px;
  }
  .footer-info-number .email2 {
    font-size: 15px;
  }
  .footer-info-number .fax1 {
    font-size: 15px;
  }
  .footer-info-number .fax2 {
    font-size: 15px;
  }
}
@media (max-width: 1023px) {
  .footer {
    width: 700px;
    height: 600px;
  }
  .footer-news {
    width: 700px;
  }
  .footer-news-head {
    font-size: 25px;
  }
  .footer-news form {
    width: 700px;
  }
  .footer-news form input {
    width: 550px;
    margin-right: 15px;
  }
  .footer-news form .button, .footer-news form .button-active {
    width: 130px;
  }
  .footer-news-please {
    width: 700px;
  }
  .footer-info {
    width: 700px;
  }
  .footer-info-head {
    margin-bottom: 20px;
    font-size: 17px;
  }
  .footer-info-beamworks {
    margin-bottom: 0px;
    font-size: 12px;
  }
  .footer-info-ceo {
    margin-bottom: 0px;
  }
  .footer-info-ceo .ceo, .footer-info-ceo .brtext {
    font-size: 12px;
  }
  .footer-info-ceo .kim, .footer-info-ceo .brnumber {
    font-size: 12px;
  }
  .footer-info-address {
    margin-bottom: 0px;
  }
  .footer-info-address .business {
    font-size: 12px;
  }
  .footer-info-address .location {
    font-size: 12px;
  }
  .footer-info-number .number1 {
    font-size: 12px;
  }
  .footer-info-number .number2 {
    font-size: 12px;
  }
  .footer-info-number .email1 {
    font-size: 12px;
  }
  .footer-info-number .email2 {
    font-size: 12px;
  }
  .footer-info-number .fax1 {
    font-size: 12px;
  }
  .footer-info-number .fax2 {
    font-size: 12px;
  }
  .footer-info-logo .beamworks {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .footer {
    width: 350px;
    height: 500px;
  }
  .footer-news {
    width: 350px;
    margin-bottom: 50px;
  }
  .footer-news-head {
    margin-bottom: 15px;
    font-size: 20px;
  }
  .footer-news form {
    width: 350px;
  }
  .footer-news form input {
    width: 240px;
    margin-right: 10px;
    height: 35px;
    font-size: 12px;
  }
  .footer-news form .button, .footer-news form .button-active {
    width: 100px;
    height: 35px;
    font-size: 12px;
  }
  .footer-news-please {
    width: 350px;
    font-size: 12px;
    line-height: 1.2;
  }
  .footer-info {
    display: grid;
    grid-template-columns: 100px 100px 150px;
    grid-template-rows: 0.5fr 0.3fr 1fr 1.3fr 1fr;
    width: 350px;
    grid-template-areas: "head head head" "beamworks beamworks number" "ceo ceo number" "address address number" "logo logo logo";
  }
  .footer-info-head {
    grid-area: head;
    font-size: 15px;
  }
  .footer-info-beamworks {
    grid-area: beamworks;
    flex-direction: column;
    flex-wrap: wrap;
    height: 20px;
    margin-bottom: 0px;
    font-size: 11px;
  }
  .footer-info-ceo {
    grid-area: ceo;
    display: grid;
    grid-template-columns: 0.5fr 2.5fr;
    margin-bottom: 0px;
    grid-template-areas: "cceo ckim" "ctext ctext" "cnumber cnumber";
  }
  .footer-info-ceo .ceo, .footer-info-ceo .brtext {
    font-size: 11px;
    grid-area: cceo;
  }
  .footer-info-ceo .kim, .footer-info-ceo .brnumber {
    font-size: 11px;
    grid-area: ckim;
    margin-left: 0px;
  }
  .footer-info-ceo .brtext {
    font-size: 11px;
    grid-area: ctext;
    margin-left: 0;
  }
  .footer-info-ceo .brnumber {
    font-size: 11px;
    grid-area: cnumber;
  }
  .footer-info-address {
    grid-area: address;
    flex-direction: column;
    margin-bottom: 0px;
  }
  .footer-info-address .business {
    margin-bottom: 3px;
    font-size: 11px;
  }
  .footer-info-address .location {
    font-size: 11px;
    line-height: 1.6;
  }
  .footer-info-number {
    margin-left: 5px;
    grid-area: number;
    flex-direction: column;
  }
  .footer-info-number .number1 {
    margin-bottom: -2px;
    font-size: 10.7px;
  }
  .footer-info-number .number2 {
    font-size: 12px;
  }
  .footer-info-number .email1 {
    margin-bottom: -5px;
    font-size: 11px;
  }
  .footer-info-number .email2 {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .footer-info-number .fax1 {
    margin-bottom: -5px;
    font-size: 11px;
  }
  .footer-info-number .fax2 {
    font-size: 12px;
  }
  .footer-info-logo {
    margin-top: 0px;
    margin-bottom: 30px;
    align-self: end;
    grid-area: logo;
  }
}/*# sourceMappingURL=footer.styles.css.map */